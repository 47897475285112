import React, { useState } from 'react';
import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	MenuItem,
	Menu,
	Box,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Fade,
	Tooltip,
	Collapse,
	CssBaseline
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBars,
	faUserCircle,
	faPowerOff,
	faTimes,
	faChartArea,
	faCheck,
	faTruck,
	faSignInAlt,
	faSignOutAlt,
	faList,
	faPlus,
	faShieldAlt
} from "@fortawesome/free-solid-svg-icons";
import clsx from 'clsx';
import Style from '../../styles/Style';
import { useAuth } from '../../context/auth.context';
import { Link } from "react-router-dom";

const useStyles = Style();

const menu = [
	{ title: "DashBoard", icon: faChartArea, link: "/", hasChild: false },
	{ title: "Cargas", icon: faTruck, link: "/carga", hasChild: false },
	{
		title: "Liberação", hasChild: true, icon: faCheck,
		collapseName: 'liberacao',
		children:
			[
				{ title: 'Liberação Entrada', link: '/liberar-entrada', icon: faSignInAlt },
				{ title: 'Liberação Carga', link: "/liberar-carga", icon: faSignOutAlt }
			]
	},
	{ title: "Blacklist", icon: faList, link: "/black-list", hasChild: false },
	{ title: "Cadastros", icon: faPlus, link: "/cadastros", hasChild: false },
	{title: "Logs", icon: faShieldAlt, link: "/logs", hasChild: false}
]

function NavBar({ children }) {
	const { logout } = useAuth();
	const classes = useStyles();
	const [openDr, setOpenDr] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [itemOpened, setItemOpened] = useState('');
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleOpenItem = (itemName) => {
		if (itemOpened === itemName) {
			setItemOpened('')
			if (openDr) {
				setOpenDr(false)
			}
		} else {
			setItemOpened(itemName)
			setOpenDr(true)
		}
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDrawer = () => {
		setOpenDr(!openDr);
		if (openDr) {
			setItemOpened('')
		}
	};

	const local = JSON.parse(localStorage.getItem('@SCHAuth:user'));

	const localAtual = window.location.pathname;

	return (
		<div className={classes.root} >
			<CssBaseline />
			<AppBar
				variant="outlined"
				position="fixed"
				color='inherit'
				className={clsx(classes.appBar, {
					[classes.appBarShift]: openDr,
				})}
				id='nao-imprimir'
			>
				<Toolbar>
					<Tooltip title={openDr ? "Fechar Menu" : "Menu"} placement={'right'} arrow>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="menu"
							onClick={handleDrawer}
							className={clsx(classes.menuButton, {
								[classes.menuButtonOpen]: openDr,
								[classes.menuButtonClose]: !openDr,
							})}
						>
							<FontAwesomeIcon icon={openDr ? faTimes : faBars} />
						</IconButton>
					</Tooltip>
					<Typography variant="h6" className={classes.titleNav} />
					<div>
						<Tooltip title={'LogOut'} arrow>
							<Box
								aria-label="account of current user"
								aria-controls="menu-appbar"
								onClick={handleClick}
								className={clsx(classes.notHide, {
									[classes.hide]: openDr,
								})}
							>
								{local.dados.nome}
								<IconButton className={classes.userIcon}>
									<FontAwesomeIcon icon={faUserCircle} />
								</IconButton>
							</Box>
						</Tooltip>
						<Menu
							anchorEl={anchorEl}
							keepMounted
							open={open}
							onClose={handleClose}
							TransitionComponent={Fade}
						>
							<MenuItem onClick={() => logout()} className={classes.listColor}>
								<FontAwesomeIcon icon={faPowerOff} />&nbsp;Sair
							</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				id='nao-imprimir'
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: openDr, [classes.drawerClose]: !openDr,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: openDr, [classes.drawerClose]: !openDr,
					}),
				}}
			>
				<div className={classes.toolbar} />
				<List>
					<List>
						{
							menu.map(m => {
								if (m.hasChild) {
									return (
										<>
											<Tooltip title={openDr? '' : m.title} placement={'right'} arrow>
												<ListItem
													onClick={() => handleOpenItem(m.collapseName)}
													className={clsx({
														[classes.backAreaMenu]: m.link === localAtual
													})}
												>
													<ListItemIcon
														className={clsx(classes.iconMenu, {
															[classes.iconMenuSelect]: m.children.some(x => x.link === localAtual),
														})}
													>
														<FontAwesomeIcon icon={m.icon} />
													</ListItemIcon>
													<Box
														className={clsx(classes.textMenu, {
															[classes.textMenuSelect]: m.children.some(x => x.link === localAtual),
														})}
													>
														<ListItemText
															primary={m.title}
														/>
														{itemOpened === m.collapseName ? <ExpandLess /> : <ExpandMore />}
													</Box>
												</ListItem>
											</Tooltip>
											<Collapse in={itemOpened === m.collapseName} timeout="auto" unmountOnExit>
												{m.children.map((lk, index) => (
													<Link to={lk.link} className={classes.listMenu}>
														<ListItem key={index} href={lk.link} className={classes.subMenu}>
															<ListItemIcon
																className={clsx(classes.iconSubMenu, {
																	[classes.iconSubMenuSelect]: lk.link === localAtual,
																})}
															>
																<FontAwesomeIcon icon={lk.icon} />
															</ListItemIcon>
															<Box
																className={clsx(classes.textSubMenu, {
																	[classes.textSubMenuSelect]: lk.link === localAtual,
																})}
															>
																<ListItemText
																	primary={lk.title}
																/>
															</Box>
														</ListItem>
													</Link>
												))}
											</Collapse>
										</>
									)
								} else {
									return (
										<Tooltip title={openDr? '' : m.title}  placement={'right'} arrow>
											<Link to={m.link} className={classes.listMenu}>
												<ListItem key={m.title}>
													<ListItemIcon
														className={clsx(classes.iconMenu, {
															[classes.iconMenuSelect]: m.link === localAtual,
														})}
													>
														<FontAwesomeIcon icon={m.icon} />
													</ListItemIcon>
													<Box
														className={clsx(classes.textMenu, {
															[classes.textMenuSelect]: m.link === localAtual,
														})}
													>
														<ListItemText primary={m.title} />
													</Box>
												</ListItem>
											</Link>
										</Tooltip>
									)
								}
							}
							)
						}
					</List>
				</List>
			</Drawer>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				{children}
			</main>
		</div>);
}

export default NavBar;
