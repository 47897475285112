// import { env } from '../environment/env';
import { Api } from './api';


export const getGeral = (props) => {
    const status = props['Situação']
    const liberarEntrada = props.liberarEntrada
    const placa = props.Placa
    const page = props.page
    const perPage = props.perPage
    const periodo = props.periodo
    const nomeProduto = props.Produto
    const nomeCliente = props.Cliente
    const nomeTransportadora = props.Transportadora
    const nomeFornecedor = props.Fornecedor
    const tipo = props.Tipo
    let urlPage = ''
    let urlPlaca = ''
    let urlStatus = ''
    let urlPerPage = ''
    let urlPeriodo = ''
    let urlProduto = ''
    let urlCliente = ''
    let urlTransportadora = ''
    let urlFornecedor = ''
    let urlTipo = ''
    let urlLiberarEntrada = ''
    const idFazenda = localStorage.getItem('@SCHAuth:fazenda') === '0' ? null : localStorage.getItem('@SCHAuth:fazenda');

    let url = `/geral?idFazenda=${idFazenda}`
    if (page !== undefined) {
        urlPage = `&page=${page}`
    }
    if (placa !== undefined) {
        urlPlaca = `&placa=${placa}`
    }
    if (status !== undefined) {
        urlStatus = `&status=${status}`
    }
    if (perPage !== undefined) {
        urlPerPage = `&perPage=${perPage}`
    }
    if (periodo !== undefined) {
        urlPeriodo = `&periodo=${periodo}`
    }
    if (nomeCliente !== undefined) {
        urlCliente = `&nomeCliente=${nomeCliente}`
    }
    if (nomeFornecedor !== undefined) {
        urlFornecedor = `&nomeFornecedor=${nomeFornecedor}`
    }
    if (nomeProduto !== undefined) {
        urlProduto = `&nomeProduto=${nomeProduto}`
    }
    if (nomeTransportadora !== undefined) {
        urlTransportadora = `&nomeTransportadora=${nomeTransportadora}`
    }
    if (tipo !== undefined) {
        urlTipo = `&tipo=${tipo}`
    }
    if (liberarEntrada !== undefined) {
        urlLiberarEntrada = `&liberarEntrada=${liberarEntrada}`
    }
    return Api.get(url + urlPage + urlPerPage + urlPlaca + urlStatus + urlPeriodo + urlCliente + urlFornecedor + urlProduto + urlTransportadora + urlTipo + urlLiberarEntrada)
}

export const getGeralEntrada = (props) => {
    const status = props['Situação']
    const placa = props.Placa
    const page = props.page
    const perPage = props.perPage
    const periodo = props.periodo
    const nomeProduto = props.Produto
    const nomeCliente = props.Cliente
    const nomeTransportadora = props.Transportadora
    const nomeFornecedor = props.Fornecedor
    const tipo = props.Tipo
    let urlPage = ''
    let urlPlaca = ''
    let urlStatus = ''
    let urlPerPage = ''
    let urlPeriodo = ''
    let urlProduto = ''
    let urlCliente = ''
    let urlTransportadora = ''
    let urlFornecedor = ''
    let urlTipo = ''
    const idFazenda = localStorage.getItem('@SCHAuth:fazenda') === '0' ? null : localStorage.getItem('@SCHAuth:fazenda');

    let url = `/geral/entrada?idFazenda=${idFazenda}`
    if (page !== undefined) {
        urlPage = `&page=${page}`
    }
    if (placa !== undefined) {
        urlPlaca = `&placa=${placa}`
    }
    if (status !== undefined) {
        urlStatus = `&status=${status}`
    }
    if (perPage !== undefined) {
        urlPerPage = `&perPage=${perPage}`
    }
    if (periodo !== undefined) {
        urlPeriodo = `&periodo=${periodo}`
    }
    if (nomeCliente !== undefined) {
        urlCliente = `&nomeCliente=${nomeCliente}`
    }
    if (nomeFornecedor !== undefined) {
        urlFornecedor = `&nomeFornecedor=${nomeFornecedor}`
    }
    if (nomeProduto !== undefined) {
        urlProduto = `&nomeProduto=${nomeProduto}`
    }
    if (nomeTransportadora !== undefined) {
        urlTransportadora = `&nomeTransportadora=${nomeTransportadora}`
    }
    if (tipo !== undefined) {
        urlTipo = `&tipo=${tipo}`
    }
    return Api.get(url + urlPage + urlPerPage + urlPlaca + urlStatus + urlPeriodo + urlCliente + urlFornecedor + urlProduto + urlTransportadora + urlTipo)
}

export const getGeralSaida = (props) => {
    const status = props['Situação']
    const placa = props.Placa
    const page = props.page
    const perPage = props.perPage
    const periodo = props.periodo
    const nomeProduto = props.Produto
    const nomeCliente = props.Cliente
    const nomeTransportadora = props.Transportadora
    const nomeFornecedor = props.Fornecedor
    const tipo = props.Tipo
    let urlPage = ''
    let urlPlaca = ''
    let urlStatus = ''
    let urlPerPage = ''
    let urlPeriodo = ''
    let urlProduto = ''
    let urlCliente = ''
    let urlTransportadora = ''
    let urlFornecedor = ''
    let urlTipo = ''
    const idFazenda = localStorage.getItem('@SCHAuth:fazenda') === '0' ? null : localStorage.getItem('@SCHAuth:fazenda');

    let url = `/geral/saida?idFazenda=${idFazenda}`
    if (page !== undefined) {
        urlPage = `&page=${page}`
    }
    if (placa !== undefined) {
        urlPlaca = `&placa=${placa}`
    }
    if (status !== undefined) {
        urlStatus = `&status=${status}`
    }
    if (perPage !== undefined) {
        urlPerPage = `&perPage=${perPage}`
    }
    if (periodo !== undefined) {
        urlPeriodo = `&periodo=${periodo}`
    }
    if (nomeCliente !== undefined) {
        urlCliente = `&nomeCliente=${nomeCliente}`
    }
    if (nomeFornecedor !== undefined) {
        urlFornecedor = `&nomeFornecedor=${nomeFornecedor}`
    }
    if (nomeProduto !== undefined) {
        urlProduto = `&nomeProduto=${nomeProduto}`
    }
    if (nomeTransportadora !== undefined) {
        urlTransportadora = `&nomeTransportadora=${nomeTransportadora}`
    }
    if (tipo !== undefined) {
        urlTipo = `&tipo=${tipo}`
    }
    return Api.get(url + urlPage + urlPerPage + urlPlaca + urlStatus + urlPeriodo + urlCliente + urlFornecedor + urlProduto + urlTransportadora + urlTipo)
}

export const getFazenda = () => {
    return Api.get(`/fazenda`)
}

export const putTriagem = (props) => {
    return Api.put(`/triagem`, props)
}

export const putNota = (props) => {
    return Api.put(`/update-nota`, props)
}

export const getProduto = (props) => {
    const page = props.page
    const perPage = props.perPage
    const search = props.search
    let urlPage = ''
    let urlPerPage = ''
    let urlSearch = ''
    let url = '/produto?active=true'
    if (page !== undefined) {
        urlPage = `&page=${page}`
    }
    if (perPage !== undefined) {
        urlPerPage = `&perPage=${perPage}`
    }
    if (search !== undefined) {
        urlSearch = `&like=true&nome=${search}`
    }

    return Api.get(url + urlPage + urlPerPage + urlSearch)
}

export const getCliente = (props) => {
    const page = props.page
    const perPage = props.perPage
    const search = props.search
    let urlPage = ''
    let urlPerPage = ''
    let urlSearch = ''
    let url = '/cliente?active=true'
    if (page !== undefined) {
        urlPage = `&page=${page}`
    }
    if (perPage !== undefined) {
        urlPerPage = `&perPage=${perPage}`
    }
    if (search !== undefined) {
        urlSearch = `&like=true&nome=${search}`
    }

    return Api.get(url + urlPage + urlPerPage + urlSearch)
}

export const getTransportadora = (props) => {
    const page = props.page
    const perPage = props.perPage
    const search = props.search
    let urlPage = ''
    let urlPerPage = ''
    let urlSearch = ''
    let url = '/transportadora?active=true'
    if (page !== undefined) {
        urlPage = `&page=${page}`
    }
    if (perPage !== undefined) {
        urlPerPage = `&perPage=${perPage}`
    }
    if (search !== undefined) {
        urlSearch = `&like=true&nome=${search}`
    }

    return Api.get(url + urlPage + urlPerPage + urlSearch)
}

export const getFornecedor = (props) => {
    const page = props.page
    const perPage = props.perPage
    const search = props.search
    let urlPage = ''
    let urlPerPage = ''
    let urlSearch = ''
    let url = '/fornecedor?active=true'
    if (page !== undefined) {
        urlPage = `&page=${page}`
    }
    if (perPage !== undefined) {
        urlPerPage = `&perPage=${perPage}`
    }
    if (search !== undefined) {
        urlSearch = `&like=true&nome=${search}`
    }

    return Api.get(url + urlPage + urlPerPage + urlSearch)
}


export const postProduto = (props) => {
    return Api.post(`/produto`, props)
}

export const postCliente = (props) => {
    return Api.post(`/cliente`, props)
}

export const postTransportadora = (props) => {
    return Api.post(`/transportadora`, props)
}

export const postFornecedor = (props) => {
    return Api.post(`/fornecedor`, props)
}

export const putProduto = (props) => {
    return Api.put(`/produto`, props)
}

export const putCliente = (props) => {
    return Api.put(`/cliente`, props)
}

export const putTransportadora = (props) => {
    return Api.put(`/transportadora`, props)
}

export const putFornecedor = (props) => {
    return Api.put(`/fornecedor`, props)
}

export const postBalancaSaida = (props) => {
    return Api.post(`/balanca-saida`, props)
}

export const getSLA = (props) => {
    const idFazenda = localStorage.getItem('@SCHAuth:fazenda') === '0' ? null : localStorage.getItem('@SCHAuth:fazenda');
    const nomeProduto = props.Produto
    const nomeCliente = props.Cliente
    const nomeTransportadora = props.Transportadora
    const nomeFornecedor = props.Fornecedor
    const placa = props.Placa
    const status = props['Situação']
    const tipo = props.Tipo

    let urlProduto = ''
    let urlCliente = ''
    let urlTransportadora = ''
    let urlFornecedor = ''
    let urlPlaca = ''
    let urlStatus = ''
    let urlTipo = ''
    const id = '?id=' + idFazenda
    let periodo = ''
    if (!!props.periodo) {
        periodo = '&periodo=' + props.periodo
    }

    if (nomeCliente !== undefined) {
        urlCliente = `&nomeCliente=${nomeCliente}`
    }
    if (nomeFornecedor !== undefined) {
        urlFornecedor = `&nomeFornecedor=${nomeFornecedor}`
    }
    if (nomeProduto !== undefined) {
        urlProduto = `&nomeProduto=${nomeProduto}`
    }
    if (nomeTransportadora !== undefined) {
        urlTransportadora = `&nomeTransportadora=${nomeTransportadora}`
    }
    if (placa !== undefined) {
        urlPlaca = `&placa=${placa}`
    }
    if (status !== undefined) {
        urlStatus = `&status=${status}`
    }
    if (tipo !== undefined) {
        urlTipo = `&tipo=${tipo}`
    }
    
    const url = '/triagem/sla' + id + periodo + urlCliente + urlFornecedor + urlProduto + urlTransportadora + urlPlaca + urlStatus + urlTipo
    return Api.get(url)
}

export const getPainel = (fazendaPainel) => {
    const idFazenda = fazendaPainel;
    let url = `/triagem/painel?idFazenda=${idFazenda}`
    return Api.get(url)
}

export const attTriagemLiberacaoEntrada = (props) => {
    let url = `/triagem/liberar-entrada`
    return Api.post(url, props)
}

export const attTriagemLiberacaoEntradaAudio = (id) => {
    let url = `/triagem/play/${id}`
    return Api.put(url)
}

export const getTriagemDash = () => {
    const idFazenda = localStorage.getItem('@SCHAuth:fazenda') === '0' ? null : localStorage.getItem('@SCHAuth:fazenda');
    return Api.get(`/triagem/embarque-producao?idFazenda=${idFazenda}`)
}

export const getTriagemDashDetail = () => {
    const idFazenda = localStorage.getItem('@SCHAuth:fazenda') === '0' ? null : localStorage.getItem('@SCHAuth:fazenda');
    return Api.get(`/triagem/embarque-producao/detail?idFazenda=${idFazenda}`)
}

export const getDetailExternal = (id) => {
    return Api.get(`/detail-external/${id}`)
}

export const getNotas = (id) => {
    return Api.get(`/notas/${id}`)
}

export const getAguardandoNF = (id) => {
    if(id !== 0) {
        return Api.get(`/aguardando-nf/${id}`)
    } 
    return Api.get(`/aguardando-nf`)
}