import React,
{
    createContext,
    useContext,
    useEffect,
    useState,
    useCallback
} from 'react';

import {
    postProduto,
    putProduto,
    getProduto,
    postCliente,
    putCliente,
    getCliente,
    postFornecedor,
    putFornecedor,
    getFornecedor,
    postTransportadora,
    putTransportadora,
    getTransportadora,
    getSLA
} from '../services/SecService';
import { useGeral } from './geral.context';

const CadContext = createContext({
    produto: [],
    fornecedor: [],
    cliente: [],
    transportadora: [],
    SLA: []
});

export const CadProvider = ({ children }) => {
    const [produto, setProduto] = useState([]);
    const [pageProduto, setPageProduto] = useState(0);
    const [perPageProduto, setPerPageProduto] = useState(10);
    const [totalLinesProduto, setTotalLinesProduto] = useState(0);
    const [cliente, setCliente] = useState([]);
    const [pageCliente, setPageCliente] = useState(0);
    const [perPageCliente, setPerPageCliente] = useState(10);
    const [totalLinesCliente, setTotalLinesCliente] = useState(0);
    const [fornecedor, setFornecedor] = useState([]);
    const [pageFornecedor, setPageFornecedor] = useState(0);
    const [perPageFornecedor, setPerPageFornecedor] = useState(10);
    const [totalLinesFornecedor, setTotalLinesFornecedor] = useState(0);
    const [transportadora, setTransportadora] = useState([]);
    const [pageTransportadora, setPageTransportadora] = useState(0);
    const [perPageTransportadora, setPerPageTransportadora] = useState(10);
    const [totalLinesTransportadora, setTotalLinesTransportadora] = useState(0);
    const [SLA, setSLA] = useState();

    const { period, rows, filter, geral } = useGeral()

    const selectProduto = useCallback(async (search) => {
        const pagination = {
            page: pageProduto,
            perPage: perPageProduto,
            search: search
        }

        try {
            const response = await getProduto(pagination);
            setProduto(response.dados)
            setTotalLinesProduto(response.lines)
        } catch (e) {
            console.error(e)
        }
    },[pageProduto, perPageProduto])

    const selectCliente =  useCallback(async (search) => {
        const pagination = {
            page: pageCliente,
            perPage: perPageCliente,
            search: search
        }
        try {
            const response = await getCliente(pagination);
            setCliente(response.dados)
            setTotalLinesCliente(response.lines)
        } catch (e) {
            console.error(e)
        }
    }, [pageCliente, perPageCliente])

    const selectFornecedor = useCallback(async (search) => {
        const pagination = {
            page: pageFornecedor,
            perPage: perPageFornecedor,
            search: search
        }
        try {
            const response = await getFornecedor(pagination);
            setFornecedor(response.dados)
            setTotalLinesFornecedor(response.lines)
        } catch (e) {
            console.error(e)
        }
    }, [pageFornecedor, perPageFornecedor] )

    const selectTransportadora = useCallback(async (search) => {
        const pagination = {
            page: pageTransportadora,
            perPage: perPageTransportadora,
            search: search
        }
        try {
            const response = await getTransportadora(pagination);
            setTransportadora(response.dados)
            setTotalLinesTransportadora(response.lines)
        } catch (e) {
            console.error(e)
        }
    }, [pageTransportadora, perPageTransportadora])

    const selectDefault = useCallback((tipo, search='') => {
        if (tipo === 'Produto') {
            selectProduto(search);
        } else if (tipo === 'Cliente') {
            selectCliente(search);
        } else if (tipo === 'Fornecedor') {
            selectFornecedor(search);
        } else if (tipo === 'Transportadora') {
            selectTransportadora(search);
        }
    },[selectProduto, selectCliente, selectFornecedor, selectTransportadora])


    const saveProduto = async (props) => {
        try {
            const user = JSON.parse(localStorage.getItem('@SCHAuth:user'));
            props.idUser = user.dados.id;
            const response = await postProduto(props);

            if (response.type !== 'Error') {
                await selectProduto()
            }
            return response;
        } catch (e) {
            console.error(e)
            return { type: "Error", message: e };
        }
    }

    const saveCliente = async (props) => {
        try {
            const user = JSON.parse(localStorage.getItem('@SCHAuth:user'));
            props.idUser = user.dados.id;
            const response = await postCliente(props)
            if (response.type !== 'Error') {
                await selectCliente('')
            }
            return response;
        } catch (e) {
            console.error(e)
            return { type: "Error", message: e };
        }
    }

    const saveFornecedor = async (props) => {
        try {
            const user = JSON.parse(localStorage.getItem('@SCHAuth:user'));
            props.idUser = user.dados.id;
            const response = await postFornecedor(props)
            if (response.type !== 'Error') {
                await selectFornecedor()
            }
            return response;
        } catch (e) {
            console.error(e)
            return { type: "Error", message: e };
        }
    }

    const saveTransportadora = async (props) => {
        try {
            const user = JSON.parse(localStorage.getItem('@SCHAuth:user'));
            props.idUser = user.dados.id;
            const response = await postTransportadora(props)
            if (response.type !== 'Error') {
                await selectTransportadora()
            }
            return response;
        } catch (e) {
            console.error(e)
            return { type: "Error", message: e };
        }
    }

    const saveDefault = async (props, tipo) => {
        var response = {};
        if (tipo === 'Produto') {
            response = await saveProduto(props)
        } else if (tipo === 'Cliente') {
            response = await saveCliente(props)
        } else if (tipo === 'Fornecedor') {
            response = await saveFornecedor(props)
        } else if (tipo === 'Transportadora') {
            response = await saveTransportadora(props)
        }
        return response;
    }

    const attProduto = async (props) => {
        try {
            const response = await putProduto(props)
            if (response.type !== 'Error') {
                await selectProduto()
            }
            return response;
        } catch (e) {
            console.error(e)
            return { type: "Error", message: e };
        }
    }

    const attCliente = async (props) => {
        try {
            const response = await putCliente(props)
            if (response.type !== 'Error') {
                await selectCliente('')
            }
            return response;
        } catch (e) {
            console.error(e)
            return { type: "Error", message: e };
        }
    }

    const attFornecedor = async (props) => {
        try {
            const response = await putFornecedor(props)
            if (response.type !== 'Error') {
                await selectFornecedor()
            }
            return response;
        } catch (e) {
            console.error(e)
            return { type: "Error", message: e };
        }
    }

    const attTransportadora = async (props) => {
        try {
            const response = await putTransportadora(props)
            if (response.type !== 'Error') {
                await selectTransportadora()
            }
            return response;
        } catch (e) {
            console.error(e)
            return { type: "Error", message: e };
        }
    }

    const attDefault = async (props, tipo) => {
        var response = {};
        if (tipo === 'Produto') {
            response = await attProduto(props)
        } else if (tipo === 'Cliente') {
            response = await attCliente(props)
        } else if (tipo === 'Fornecedor') {
            response = await attFornecedor(props)
        } else if (tipo === 'Transportadora') {
            response = await attTransportadora(props)
        }
        return response;
    }

    const getSLADefault = useCallback(async () => {
        const props = {
            periodo: period,
            ...filter
        }

        try {
            const result = await getSLA(props)
            setSLA(result.detail)
            // setSLAFiial(result.dados)
        } catch (e) {
            console.error(e)
        }
    },[period, filter])

    useEffect(() => {
        selectDefault()
    }, [selectDefault])

    useEffect(() => {
        getSLADefault()
    }, [getSLADefault, geral, rows])

    useEffect(() => {
        selectProduto()
    }, [selectProduto])

    useEffect(() => {
        selectCliente()
    }, [selectCliente])

    useEffect(() => {
        selectFornecedor()
    }, [selectFornecedor])

    useEffect(() => {
        selectTransportadora()
    }, [selectTransportadora])

    return (
        <CadContext.Provider
            value={{
                saveProduto,
                saveCliente,
                saveFornecedor,
                saveTransportadora,
                saveDefault,
                attProduto,
                attCliente,
                attFornecedor,
                attTransportadora,
                attDefault,
                selectDefault,
                getSLADefault,
                setPageCliente,
                setPerPageCliente,
                setTotalLinesCliente,
                selectCliente,
                setPageFornecedor,
                setPerPageFornecedor,
                setTotalLinesFornecedor,
                selectFornecedor,
                setPageProduto,
                setPerPageProduto,
                setTotalLinesProduto,
                selectProduto,
                setPageTransportadora,
                setPerPageTransportadora,
                setTotalLinesTransportadora,
                selectTransportadora,
                produto,
                pageProduto,
                perPageProduto,
                totalLinesProduto,
                cliente,
                pageCliente,
                perPageCliente,
                totalLinesCliente,
                transportadora,
                pageTransportadora,
                perPageTransportadora,
                totalLinesTransportadora,
                fornecedor,
                pageFornecedor,
                perPageFornecedor,
                totalLinesFornecedor,
                SLA
            }}
        >
            {children}
        </CadContext.Provider>
    );
};

export function useCad() {
    const context = useContext(CadContext);
    return context;
}
