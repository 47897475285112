import React, { forwardRef, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import {
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Grid,
    Paper,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Style from '../../../styles/Style';
import { useCad } from '../../../context/cad.context';
import { useGeral } from '../../../context/geral.context';

const useStyle = Style()

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const tipoEntrada = [
    { value: 'EP', nome: 'Embarque de produção' },
    { value: 'DP', nome: 'Desembarque de produção' },
    { value: 'EM', nome: 'Entrega de mercadoria' },
    { value: 'SE', nome: 'Serviços' },
    { value: 'VI', nome: 'Visitantes' },
    { value: 'CO', nome: 'Colaboradores' },
    { value: 'MO', nome: 'Moradores' },
    { value: 'OT', nome: 'Outros' },
]

const inputAdd = [
    {
        label: 'Produto',
        tipoEntrada: [
            { tipo: 'EP' },
            { tipo: 'DP' },
            { tipo: 'EM' },
        ]
    },
    {
        label: 'Cliente',
        tipoEntrada: [
            { tipo: 'EP' },
        ]
    },
    {
        label: 'Fornecedor',
        tipoEntrada: [
            { tipo: 'EM' },
            { tipo: 'EP' },
        ]
    },
    {
        label: 'Transportadora',
        tipoEntrada: [
            { tipo: 'EP' },
        ]
    },
    {
        label: 'Ticket',
        tipoEntrada: [
            { tipo: 'EP' },
        ]
    },
    {
        label: 'Outros',
        tipoEntrada: [
            { tipo: 'SE' },
            { tipo: 'VI' },
            { tipo: 'CO' },
            { tipo: 'MO' },
            { tipo: 'OT' },
        ]
    },
]


const ModalEdit = ({ props }) => {
    const timeoutRef = useRef(null);
    const open = props.open;
    const handleClose = props.handleClose;
    const data = props.data;

    const classes = useStyle();
    const { fornecedor, cliente, transportadora, produto, selectCliente, selectFornecedor, selectTransportadora, selectProduto } = useCad();
    const { attEProducao } = useGeral();
    const [tipo, setTipo] = useState()
    const [focusCliente, setFocusCliente] = useState();
    const [searchCliente, setSearchCliente] = useState();
    const [focusProduto, setFocusProduto] = useState();
    const [searchProduto, setSearchProduto] = useState();
    const [focusTransportadora, setFocusTransportadora] = useState();
    const [searchTransportadora, setSearchTransportadora] = useState();
    const [focusFornecedor, setFornecedor] = useState();
    const [searchFornecedor, setSearchFornecedor] = useState();


    const onChangeSearchCliente = (search) => {
        setSearchCliente(search)
    }

    const onChangeCliente = (options) => {
        setFocusCliente(options)
        return options.nome
    }

    const onChangeSearchProduto = (search) => {
        setSearchProduto(search)
    }

    const onChangeProduto = (options) => {
        setFocusProduto(options)
        return options.nome
    }

    const onChangeSearchTransportadora = (search) => {
        setSearchTransportadora(search)
    }

    const onChangeTransportadora = (options) => {
        setFocusTransportadora(options)
        return options.nome
    }

    const onChangeSearchFornecedor = (search) => {
        setSearchFornecedor(search)
    }

    const onChangeFornecedor = (options) => {
        setFornecedor(options)
        return options.nome
    }

    const handleTipo = (props) => {
        setTipo(props)
    }

    const handleSumbit = event => {
        const obj = {}
        obj.id = data.idTriagem;
        obj.placa = event.target.placa.value;
        obj.motorista = event.target.motorista.value;
        obj.qtdPassageiro = parseInt(event.target.qtdPassageiro.value);
        obj.tipo = event.target.tipo.value;
        obj.idCliente = focusCliente.id;
        obj.idProduto = focusProduto.id;
        obj.idFornecedor = focusFornecedor.id;
        obj.idTransportadora = focusTransportadora.id;
        obj.obs = event.target.obs?.value;
        obj.numInstrucao = event.target.numInstrucao?.value;
        obj.ticket = parseInt(event.target.ticket?.value);

        attEProducao(obj, {})
    }

    const fillHidden = (props) => {
        const type = tipo === undefined ? data.tipo : tipo

        const result = inputAdd.some(x => x.label === props && x.tipoEntrada.some(x => x.tipo === type))
        return result
    }

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            selectCliente(searchCliente);
        }, 1000);
        // eslint-disable-next-line
    }, [searchCliente]);


    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            selectFornecedor(searchFornecedor);
            // eslint-disable-next-line
        }, 1000);
        // eslint-disable-next-line
    }, [searchFornecedor]);


    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            selectProduto(searchProduto);
            // eslint-disable-next-line
        }, 1000);
        // eslint-disable-next-line
    }, [searchProduto]);

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            selectTransportadora(searchTransportadora);
        }, 1000);
        // eslint-disable-next-line
    }, [searchTransportadora]);

    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} >
                <Toolbar className={classes.toolbarEdit}>
                    <Typography variant="h6" className={classes.title} />

                    <Typography variant="h6" className={classes.title}>
                        Editar Triagem
                    </Typography>
                    <IconButton edge="start" color="secondary" aria-label="close" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <form onSubmit={handleSumbit} style={{ marginTop: 40 }}>
                <Grid container className={classes.root}>
                    <Grid item xs={false} sm={2} md={2} >
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Paper className={classes.columnEdit}>
                            <Typography variant={'h6'} color={'textSecondary'} >Dados da Carga</Typography>
                            <div className={classes.inputEdit}>
                                <TextField
                                    label="Placa"
                                    variant="outlined"
                                    defaultValue={data.placa}
                                    name={'placa'}
                                    fullWidth
                                    autoCapitalize
                                    required
                                    className={classes.selectEdit}
                                />
                            </div>
                            <div className={classes.inputEdit}>
                                <TextField
                                    label="Motorista"
                                    variant="outlined"
                                    defaultValue={data.motorista}
                                    name={'motorista'}
                                    fullWidth
                                    required
                                    className={classes.selectEdit}
                                />
                            </div>
                            <div className={classes.inputEdit}>

                                <TextField
                                    label="Qtd. Passageiros"
                                    variant="outlined"
                                    defaultValue={data.qtdPassageiro}
                                    name={'qtdPassageiro'}
                                    fullWidth
                                    className={classes.selectEdit
                                    } />
                            </div>
                            <div className={classes.inputEdit}>
                                <FormControl variant="outlined" fullWidth className={classes.selectEdit}>
                                    <InputLabel>Tipo Entrada</InputLabel>
                                    <Select
                                        label="Tipo Entrada"
                                        defaultValue={data.tipo}
                                        name={'tipo'}
                                        onChange={(e) => handleTipo(e.target.value)}
                                    >
                                        {tipoEntrada.map((l, i) => (
                                            <MenuItem key={i} value={l.value}>{l.nome}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.inputEdit}>
                                <TextField
                                    label="Obseravação"
                                    defaultValue={data.obs}
                                    multiline
                                    minRows={5}
                                    name={'obs'}
                                    variant="outlined"
                                    fullWidth
                                    className={classes.selectEdit}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} >
                        <Paper className={classes.columnEdit}>
                            <Typography variant={'h6'} color={'textSecondary'} >Dados complementares!</Typography>
                            <div
                                className={clsx(classes.inputEdit, {
                                    [classes.inputHidden]: !fillHidden('Outros')
                                })}
                            >
                                <Typography variant={'body1'} color={'textSecondary'} >Não há dados complementares para este tipo de triagem!</Typography>
                            </div>
                            <div
                                className={clsx(classes.inputEdit, {
                                    [classes.inputHidden]: !fillHidden('Cliente')
                                })}
                            >
                                <FormControl variant="outlined" fullWidth className={classes.selectEdit} >
                                    <Autocomplete
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => onChangeCliente(option)}
                                        options={cliente}
                                        defaultValue={{ id: data.idCliente, nome: data.nomeCliente }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Cliente"
                                                variant="outlined"
                                                name={'cliente'}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                onChange={(e) => onChangeSearchCliente(e.target.value)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </div>
                            <div
                                className={clsx(classes.inputEdit, {
                                    [classes.inputHidden]: !fillHidden('Produto')
                                })}
                            >
                                <FormControl variant="outlined" fullWidth className={classes.selectEdit}>
                                    <Autocomplete
                                        id="asynchronous-demo"
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => onChangeProduto(option)}
                                        options={produto}
                                        defaultValue={{ id: data.idProduto, nome: data.nomeProduto }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Produto"
                                                variant="outlined"
                                                name={'produto'}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                onChange={(e) => onChangeSearchProduto(e.target.value)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </div>
                            <div
                                className={clsx(classes.inputEdit, {
                                    [classes.inputHidden]: !fillHidden('Fornecedor')
                                })}
                            >
                                <FormControl variant="outlined" fullWidth className={classes.selectEdit}>
                                    <Autocomplete
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => onChangeFornecedor(option)}
                                        options={fornecedor}
                                        defaultValue={{ id: data.idFornecedor, nome: data.nomeFornecedor }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Fornecedor"
                                                variant="outlined"
                                                name={'fornecedor'}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                onChange={(e) => onChangeSearchFornecedor(e.target.value)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </div>
                            <div
                                className={clsx(classes.inputEdit, {
                                    [classes.inputHidden]: !fillHidden('Transportadora')
                                })}
                            >
                                <FormControl variant="outlined" fullWidth className={classes.selectEdit}>
                                    <Autocomplete
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => onChangeTransportadora(option)}
                                        options={transportadora}
                                        defaultValue={{ id: data.idTransportadora, nome: data.nomeTransportadora }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Transportadora"
                                                variant="outlined"
                                                name={'transportadora'}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                onChange={(e) => onChangeSearchTransportadora(e.target.value)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </div>
                            <div
                                className={clsx(classes.inputEdit, {
                                    [classes.inputHidden]: !fillHidden('Ticket')
                                })}
                            >
                                <TextField
                                    label="N° Instrução"
                                    variant="outlined"
                                    name={'numInstrucao'}
                                    defaultValue={data.numInstrucao}
                                    fullWidth
                                    className={classes.selectEdit}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={false} sm={2} md={2} >
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item xs={false} sm={2} md={4} />
                    <Grid item xs={12} sm={8} md={4} >
                        <Paper className={classes.columnEdit}>
                            <div className={classes.inputEdit}>
                                <Button type={'submit'} autoFocus className={classes.saveEdit} fullWidth>
                                    Salvar
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={false} sm={2} md={4} />
                </Grid>
            </form >
        </Dialog >
    )

}

export default ModalEdit;