import { makeStyles } from "@material-ui/core/styles";

import Color from "../../../../styles/Color";

export const MainPage = () => {
  return makeStyles((theme) => ({
    mainTitle: { 
      flex: 1,
      flexDirection: "row",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 15
    },
    title: {
      fontWeight: 'bold', 
      fontSize: 20, 
      color: Color.primaryTextLight
    },
    mainSubtitle: { 
      flex: 1,
      flexDirection: "row",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      margin: 15
    },
    subtitle: {
      fontWeight: 'bold', 
      fontSize: 18, 
      color: Color.primaryTextLight
    }
  }));
}


export const ComponentDetailPage = () => {
  return makeStyles((theme) => ({
    head: {
      flex: 1,
      flexDirection: "column",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 100,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 3,
      borderBottomLeftRadius: 3,
      borderRightWidth: 0,
      background: Color.inputLight,
      color: Color.primaryTextLight,
      padding: 15,
    },
    titleHead: {
      fontWeight: 'bold',
      textAlign: "center",
      fontSize: 15
    },
    titleColor: {
      background: Color.inputLight, 
      width: 30, 
      height: 20
    },
    main: {
      margin: 5,
      padding: 5,
      background: Color.light
    },
    body: {
      flex: 1,
      flexDirection: 'column',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
    },
    bodyTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    title: {
      fontSize: 18,
      color: Color.primaryText,
      fontWeight: 'bold'
    },
    bodyComponent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    line: {
      marginBottom: 1, 
      marginTop: 1
    },
    component: {
      flex: 1,
      flexDirection: "column",
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: 5
    },
    componentBody: {
      flex: 1,
      flexDirection: "column",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 5
    },
    textBody: {
      fontSize: 20, 
      color: Color.primaryText, 
      fontWeight: 'bold'
    },
    componentBodyTotal: {
      flex: 1,
      flexDirection: "column",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 5,
      background: Color.inputLight
    },
    textBodyTotal: {
      fontSize: 20, 
      color: Color.primaryText, 
      fontWeight: 'bold'
    },
    chipBody: {
      background: Color.secundaryText,
      color: Color.primaryText,
      fontSize: 15
    },
    totalBody: {
      flex: 1,
      flexDirection: "column",
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    totalText: {
      fontSize: 18,
      color: Color.primaryText,
      fontWeight: 'bold'
    },
    totalFooter: {
      fontSize: 20,
      color: Color.secundaryText,
      fontWeight: 'bold'
    },
    mainButtonExpanded: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'flex-end',
      marginRight: 10
    },
    buttonExpanded: {
      borderRadius: 30,
    }
  }));
}
