import { Collapse, Box } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from "../context/auth.context"
import { useGeral } from "../context/geral.context";
import Style from "../styles/Style";

const useStyle = Style();

export const AlertLogin = () => {
    const { open, alert } = useAuth();
    const classes = useStyle()

    return (
        <Box className={classes.alertLogin}>
            <Collapse in={open} >
                <Alert
                    severity={alert.msg}
                    iconMapping={{
                        success: <FontAwesomeIcon icon={faCheckCircle} />,
                        error: <FontAwesomeIcon icon={faTimesCircle} />,
                    }}
                >
                    {alert.desc}
                </Alert>
            </Collapse>
        </Box>

    )
}

export const AlertApi = () => {
    const { open, alert } = useGeral();
    const classes = useStyle()

    return (
        <Box className={classes.alertApi}>
            <Collapse in={open} >
                <Alert
                    severity={alert.msg}
                    iconMapping={{
                        success: <FontAwesomeIcon icon={faCheckCircle} />,
                        error: <FontAwesomeIcon icon={faTimesCircle} />,
                        warning: <FontAwesomeIcon icon={faExclamationCircle} />,
                    }}
                >
                    {alert.desc}
                </Alert>
            </Collapse>
        </Box>

    )
}