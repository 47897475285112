import { Typography } from "@material-ui/core";
import DetailEMComponet from "./DetailEM.component";
import DetailEPComponet from "./DetailEP.component";

const DetailAllComponent = ({data}) => {
  if (data?.tipo === 'EP') {
    return <DetailEPComponet data={data}/>
  }
  if (data?.tipo === 'EM') {
    return <DetailEMComponet data={data}/>
  }

  return <Typography variant="h6" style={{padding: 10}}>Nada aqui!</Typography>
}

export default DetailAllComponent;