import axios from 'axios';
import {env} from "../environment/env";

const apiUrl = env.baseUrl;

export const api = axios.create({
    baseURL: env.baseUrl,
});

export const Api = {

    get: async (endpoint) => {
        const tokenStored = JSON.parse(localStorage.getItem('@SCHAuth:token'))
        return axios
            .get(`${apiUrl}${endpoint}`, {
                headers: {
                    Authorization: `Bearer ${tokenStored}`
                }
            })
            .then(r => r.data)
    },
    post: async (endpoint, body) => {

        const tokenStored = JSON.parse(localStorage.getItem('@SCHAuth:token'))

        return axios
            .post(`${apiUrl}${endpoint}`, {
                ...body
            }, {
                headers: {
                    Authorization: `Bearer ${tokenStored}`
                }
            })
            .then(r => r.data)
    },
    put: async (endpoint, body) => {

        const tokenStored = JSON.parse(localStorage.getItem('@SCHAuth:token'))

        return axios
            .put(`${apiUrl}${endpoint}`, {
                ...body
            }, {
                headers: {
                    Authorization: `Bearer ${tokenStored}`
                }
            })
            .then(r => r.data)
    },
    delete: async (endpoint, body) => {
        try {
            const tokenStored = JSON.parse(localStorage.getItem('@SCHAuth:token'))

            return axios
                .delete(`${apiUrl}${endpoint}`, {
                    headers: {
                        Authorization: `Bearer ${tokenStored}`
                    },
                    data: {
                        ...body
                    }
                })
                .then(r => r.data)
        } catch (e) {
            throw e
        }
    }

}
