import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core';
import SelectFazenda from "../geral/SelectFazenda";
import { convertDateDetail } from '../../util/formatDate';
import { MainPage } from './components/style/Dashboard.styte';
import AguardandoNfView from './components/AguardandoNfView';

function HomeAguardandoNF() {
  const mainClass = MainPage();
  const classe = mainClass();
  const [date, setDate] = useState(convertDateDetail());

  useEffect(() => {
    var timerID = setInterval(() => setDate(convertDateDetail()), 1000);
 
    return () => clearInterval(timerID);
  });
 
  return (
    <div >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item xs={2} sm={2} md={2} >
          <SelectFazenda />
        </Grid>
        <Grid item xs={1} sm={1} md={1} />
        <Grid item xs={4} sm={4} md={4} >
          <div className={classe.mainTitle}>
            <Typography variant="button" className={classe.title}>
              Aguardando NF
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} >
          <div className={classe.mainSubtitle}>
            <Typography variant="button" className={classe.subtitle}>
              {date}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item xs={12} sm={12} md={12} >
          <AguardandoNfView />
        </Grid>
      </Grid>
    </div>
  )
}

export default HomeAguardandoNF;
