import React, { useState, useEffect } from "react";
import {
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Box,
    TableHead,
    IconButton,
    Avatar
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faCheck,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";

import Style from "../../../styles/Style";
import Color from "../../../styles/Color";
import { useGeral } from "../../../context/geral.context";
import ModalLiberacao from "./ModalLiberacao";
import PaginationBar from "../../geral/PaginationBar";
import { getGeralSaida } from "../../../services/SecService";

const useStyles = Style();

function TabLiberacao() {
    const classes = useStyles();
    const { period, selectFazenda, filter } = useGeral();
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalLines, setTotalLines] = useState(0)
    const [modal, setModal] = useState(false);
    const [value, setValue] = useState({ placa: '' });

    const syncGeralSaida = async () => {
        const pagination = {
            page: page,
            perPage: rowsPerPage,
            periodo: period,
            ...filter
        }
        try {
            const response = await getGeralSaida(pagination);
            const dados = response.dados
            setRows(dados)
            setTotalLines(response.lines)
        } catch (e) {
            console.log(e)
        }
    }

    const rowsFilter = rows

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalLines - page * rowsPerPage);

    const emp = Array.from(Array(emptyRows).keys());

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openModal = (value) => {
        setModal(!modal)
        setValue(value)
    }

    const closeModal = () => {
        setModal(!modal)
    }
    useEffect(() => {
        syncGeralSaida()
        // eslint-disable-next-line
    }, [period, selectFazenda, page, rowsPerPage])


    useEffect(() => {
        if(!modal) {
            syncGeralSaida()
        }
        // eslint-disable-next-line
    }, [modal])

    return (
        <div>
            <Paper className={classes.rootDashAlt} elevation={2}>
                <TableContainer className={classes.paperTable} >
                    <Table className={classes.table} aria-label="custom pagination table" size={'small'}>
                        <TableHead >
                            <TableRow>
                                <TableCell align={'center'} className={classes.tableHead}>
                                    <Typography color="textSecondary">
                                        <b>Liberado?</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Motorista</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Placa</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Produto</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Cliente</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Transportadora</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Triagem</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Entrada</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Nota Fiscal</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>

                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsFilter).map((row) => (
                                <TableRow
                                    hover
                                    key={row.idTriagem}
                                >
                                    <TableCell component="th" scope="row" align={'center'}>
                                        <Typography>
                                            <FontAwesomeIcon
                                                icon={row.liberar ? faCheck : faTimes}
                                                color={row.liberar ? Color.antColor : Color.closeColor}
                                                size={'lg'}
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary">
                                            {row.motorista}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary">
                                            {(row.placa).substring(0, 3) + "-" + (row.placa).substring(3, 8)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary">
                                            {row.nomeProduto}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary">
                                            {(row.nomeCliente).length > 12 ? (row.nomeCliente).substring(0, 12) + '...' : row.nomeCliente}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary">
                                            {(row.nomeTransportadora).length > 12 ? (row.nomeTransportadora).substring(0, 12) + '...' : row.nomeTransportadora}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary">
                                            {row.dataTriagem}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary">
                                            {row.dataEntrada}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary">
                                            {row.notaFiscal === null || row.notaFiscal === 0 ? 'Não vinculada' : row.notaFiscal}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Box className={classes.tableAction}>
                                            <IconButton
                                                className={classes.iconButtoBody}
                                                size={'small'}
                                                onClick={() => openModal(row)}
                                            >
                                                <Avatar className={classes.avatarSmall} >
                                                    <FontAwesomeIcon icon={faPlus} size={'xs'} />
                                                </Avatar>
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}

                            {emptyRows > 0 && (
                                emp.map((l, i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={10}>
                                            <Typography>
                                                &nbsp;<br />
                                                &nbsp;
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <PaginationBar
                label={'Linhas por Página'}
                listRows={[5, 10]}
                rowsPage={rowsPerPage}
                len={totalLines}
                changePage={handleChangePage}
                changeRowsPage={handleChangeRowsPerPage}
                page={page}
            />
            <ModalLiberacao props={{
                modal: modal,
                handleModal: closeModal,
                value: value
            }}
            />
        </div >
    )
}

export default TabLiberacao;