import { Paper, Grid, Typography } from "@material-ui/core";
import { useGeral } from "../../../context/geral.context";
import Color from "../../../styles/Color";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

const EmbaqueProducaoView = () => {
  const {
    embaqueProducao,
    embaqueProducaoResumo
  } = useGeral()
  
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={0}
      >
        <Grid item xs={12} sm={12} md={2} />
        <Grid item xs={12} sm={12} md={2} >
          <Paper
            style={{
              flex: 1,
              flexDirection: "column",
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              height: 100,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              borderRightWidth: 0,
              background: Color.inputLight,
              color: Color.primaryTextLight,
              padding: 15
            }}
            elevation={1}
          >
            <Typography variant="button" style={{ fontWeight: 'bold' }}>
              Aguardando Entrada
            </Typography>
            <Typography variant="button">
              {embaqueProducaoResumo?.aguardando}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Paper
            style={{
              flex: 1,
              flexDirection: "column",
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              height: 100,
              borderRadius: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              background: Color.inputLight,
              color: Color.primaryTextLight,
              padding: 15
            }}
            elevation={1}
          >
            <Typography variant="button" style={{ fontWeight: 'bold' }}>
              Aguardando Carregamento
            </Typography>
            <Typography variant="button">
              {embaqueProducaoResumo?.aguardando_carregamento}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Paper
            style={{
              flex: 1,
              flexDirection: "column",
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              height: 100,
              borderRadius: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              background: Color.inputLight,
              color: Color.primaryTextLight,
              padding: 15
            }}
            elevation={1}
          >
            <Typography variant="button" style={{ fontWeight: 'bold' }}>
              Aguardando NF-S
            </Typography>
            <Typography variant="button">
              {embaqueProducaoResumo?.aguardando_nf}
            </Typography>

          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Paper
            style={{
              flex: 1,
              flexDirection: "column",
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              height: 100,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 3,
              borderBottomRightRadius: 3,
              borderLeftWidth: 0,
              background: Color.inputLight,
              color: Color.primaryTextLight,
              padding: 15
            }}
            elevation={1}
          >
            <Typography variant="button" style={{ fontWeight: 'bold' }}>
              Aguardando Saída (NF-S)
            </Typography>
            <Typography variant="button">
              {embaqueProducaoResumo?.aguardando_saida}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Paper
            style={{
              flex: 1,
              flexDirection: "column",
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              height: 100,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 3,
              borderBottomRightRadius: 3,
              borderLeftWidth: 0,
              background: Color.inputLight,
              color: Color.primaryTextLight,
              padding: 15
            }}
            elevation={1}
          >
            <Typography variant="button" style={{ fontWeight: 'bold' }}>
              Concluídos (hoje)
            </Typography>
            <Typography variant="button">
              {embaqueProducaoResumo?.concluido}
            </Typography>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={1} /> */}
      </Grid>

      {
        embaqueProducao.map((x, index) => (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={1}
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <Grid item xs={2} sm={2} md={2} style={{ background: Color.atuColor, borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }} >

              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 90,
                  border: 'none',
                  color: Color.secundaryText,
                  background: Color.atuColor,
                }}
              >
                <FontAwesomeIcon icon={faCaretUp} color={Color.secundaryText} size={'lg'} />
                <Typography variant="button" style={{ fontSize: 14, textAlign: "center" }} >
                  {x?.produto}
                </Typography>
                <Typography variant="button">
                  {x?.total}
                </Typography>
                <FontAwesomeIcon icon={faCaretDown} color={Color.secundaryText} size={'lg'} />
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} style={{ background: Color.atuColor }} >
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 80,
                  border: 'none',
                  borderRadius: 3,
                  background: Color.secundaryText,
                  margin: 5
                }}
              >
                <Typography variant="h5" style={{ fontWeight: 'bold', color: Color.primaryTextLight }}>
                  {x?.aguardando}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} style={{ background: Color.atuColor }} >
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 80,
                  border: 'none',
                  borderRadius: 3,
                  background: Color.secundaryText,
                  margin: 5
                }}
              >
                <Typography variant="h5" style={{ fontWeight: 'bold', color: Color.primaryTextLight }}>
                  {x?.aguardando_carregamento}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} style={{ background: Color.atuColor }} >
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 80,
                  border: 'none',
                  borderRadius: 3,
                  background: Color.secundaryText,
                  margin: 5
                }}
              >
                <Typography variant="h5" style={{ fontWeight: 'bold', color: Color.primaryTextLight }}>
                  {x?.aguardando_nf}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} style={{ background: Color.atuColor, borderTopRightRadius: 5, borderBottomRightRadius: 5 }} >
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 80,
                  border: 'none',
                  borderRadius: 3,
                  background: Color.secundaryText,
                  margin: 5,
                }}
              >
                <Typography variant="h5" style={{ fontWeight: 'bold', color: Color.primaryTextLight }}>
                  {x?.aguardando_saida}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} style={{ background: Color.atuColor, borderTopRightRadius: 5, borderBottomRightRadius: 5 }} >
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 80,
                  border: 'none',
                  borderRadius: 3,
                  background: Color.secundaryText,
                  margin: 5,
                }}
              >
                <Typography variant="h5" style={{ fontWeight: 'bold', color: Color.primaryTextLight }}>
                  {x?.concluido}
                </Typography>
              </div>
            </Grid>
            {/* <Grid item xs={1} sm={1} md={1} /> */}
          </Grid>
        ))
      }
    </div >
  )
}

export default EmbaqueProducaoView;