export const parseDate = prop => {
    var dia = (prop).substring(0, 2)
    var mes = (prop).substring(3, 5)
    var ano = (prop).substring(6, 10)
    var hora = (prop).substring(11, 19)
    var parseDataShort = Date.parse(mes + "/" + dia + "/" + ano + " " + hora);

    return parseDataShort
}


export const timezoneDate = prop => {
    var parseDataShort = parseDate(prop)
    var dateFormat = new Date(parseDataShort)

    return dateFormat
}

export const valueDate = prop => {
    var parseDataShort = Date.parse(prop)
    var dateFormat = new Date(parseDataShort)

    return dateFormat.toLocaleString()
}

export const convertDateDetail = (dateString) => {
    let date;
    let day;
    let month;
    let year;
    let hour;
    let minute;

    if (dateString === undefined) {
        date = new Date()
        day = ("00" + date.getDate()).slice(-2)
        month = ("00" + (date.getMonth() + 1)).slice(-2)
        year = date.getFullYear().toString()
        hour = ("00" + (date.getHours())).slice(-2)
        minute = ("00" + (date.getMinutes())).slice(-2)
    } else {
        date = new Date(dateString)
        day = ("00" + date.getDate()).slice(-2)
        month = ("00" + (date.getMonth() + 1)).slice(-2)
        year = date.getFullYear().toString()
        hour = ("00" + (date.getHours())).slice(-2)
        minute = ("00" + (date.getMinutes())).slice(-2)
    }

    return day + "/" + month + "/" + year + " " + hour + ":" + minute
}