import { useEffect } from "react"
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import { useCad } from "../../../context/cad.context";
import Style from "../../../styles/Style";
import Color from "../../../styles/Color";
import { useGeral } from "../../../context/geral.context";

const useStyles = Style();

const CardSLAComponent = () => {

  const classes = useStyles();

  const { getSLADefault, SLA } = useCad()
  const { selectFazenda, filter } = useGeral()

  const convertSecondToTime = (given_seconds) => {
    const hours = Math.floor(given_seconds / 3600);
    const minutes = Math.floor((given_seconds - (hours * 3600)) / 60);
    const seconds = Math.trunc(given_seconds - (hours * 3600) - (minutes * 60));

    const timeString = hours.toString().padStart(2, '0') + ':' +
      minutes.toString().padStart(2, '0') + ':' +
      seconds.toString().padStart(2, '0');
    return timeString
  }

  useEffect(() => {
    getSLADefault()
    // eslint-disable-next-line
  }, [selectFazenda, filter])

  return (

    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      justifyContent="space-between"
    >
      <Grid item xs={4} sm={4} md={4}>
        <Card className={classes.card} elevation={1}>
          <CardContent className={classes.cardDash}>
            <Box
              className={classes.cardBox}
            >
              <Box
                className={classes.cardIcon}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  color={Color.secundaryText}
                  size={'3x'}
                />
              </Box>
              <Box className={classes.cardText}>
                <Typography variant="h3" component="h2" color="textSecondary">
                  {convertSecondToTime(SLA?.tempoEntrada)}
                </Typography>
                <Typography color="textSecondary" variant={'subtitle1'}>
                  Tempo Médio até a entrada
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card >
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <Card className={classes.card} elevation={1}>
          <CardContent className={classes.cardDash}>
            <Box
              className={classes.cardBox}
            >
              <Box
                className={classes.cardIcon}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  color={Color.secundaryText}
                  size={'3x'}
                />
              </Box>
              <Box className={classes.cardText}>
                <Typography variant="h3" component="h2" color="textSecondary">
                  {convertSecondToTime(SLA?.tempoSaida)}
                </Typography>
                <Typography color="textSecondary" variant={'subtitle1'}>
                  Tempo Médio até a Saída
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card >
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <Card className={classes.card} elevation={1}>
          <CardContent className={classes.cardDash}>
            <Box
              className={classes.cardBox}
            >
              <Box
                className={classes.cardIcon}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  color={Color.secundaryText}
                  size={'3x'}
                />
              </Box>
              <Box className={classes.cardText}>
                <Typography variant="h3" component="h2" color="textSecondary">
                  {convertSecondToTime(SLA?.tempoTotal)}
                </Typography>
                <Typography color="textSecondary" variant={'subtitle1'}>
                  Tempo Médio total entre triagem e saída
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card >
      </Grid>
    </Grid>
  )
}

export default CardSLAComponent