import React from 'react'

import {
    Grid,
    CssBaseline,
} from "@material-ui/core";

import Painel from "./components/Painel";

function PainelLiberacao() {
    return (
        <div>
            <Grid container >
                <CssBaseline />
                <Grid item xs={12} sm={12} md={12}>
                    <Painel />
                </Grid>
            </Grid>
        </div>
    )
}

export default PainelLiberacao;
