import React, {createContext, useContext, useEffect, useState, useCallback} from 'react';

import { api } from '../services/api';
import { loginServer, validServer, refreshToken } from '../services/AuthService';
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({
    login: null,
    logout: null,
    signed: false,
    user: null
});

export const AuthProvider = ({children}) => {

    const [user, setUser] = useState();
    const [redirectTo, setRedirectTo] = useState('/');
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ msg: 'Success', desc: undefined })

    const navigate = useNavigate();

    const login = async (user, pass, origem) => {
        try {
            setLoading(true)
            if (user !== undefined || pass !== undefined) {
                const loginResponse = await loginServer(user, pass, origem)
                if (loginResponse.authorization) {
                    const userResponse = {
                        dados: loginResponse.dados,
                        permission: loginResponse.permission
                    };

                    localStorage.setItem('@SCHAuth:user', JSON.stringify(userResponse));
                    localStorage.setItem('@SCHAuth:token', JSON.stringify(loginResponse.access_token))
                    localStorage.setItem('@SCHAuth:refresh', JSON.stringify(loginResponse.refresh_token))

                    setUser(userResponse);

                    setAlert({ msg: 'success', desc: 'Autorizado!' })
                    navigate('/')
                } else {
                    setAlert({ msg: 'error', desc: loginResponse.permission })
                }
            } else {
                setAlert({ msg: 'error', desc: 'Campos em Branco!' })
            }
        } catch (e) {
            setAlert({ msg: 'error', desc: 'Erro geral tente novamente' })
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 5000)
        }
    }

    function logout() {
        setUser(null);
        localStorage.clear();
    }

    const handleRefreshToken = useCallback(async (token) => {
        try {
            const response = await refreshToken(token);
            const token_refresh = response.access_token;
            localStorage.setItem('@SCHAuth:token', JSON.stringify(token_refresh))
            api.defaults.headers.common.Authorization = `Bearer ${token_refresh.replace(/"/g, '',)}`;
        } catch (e) {
            logout()
        }
    },[])

    const checkLogin = useCallback(async () => {
        try {
            setLoading(true)
            const tokenStored = JSON.parse(localStorage.getItem('@SCHAuth:token'))
            const refreshTokenStored = JSON.parse(localStorage.getItem('@SCHAuth:refresh'))
            const userStored = JSON.parse(localStorage.getItem('@SCHAuth:user'))

            if (!tokenStored || !refreshTokenStored) {
                setLoading(false)
                logout()
                return
            }

            await validServer(tokenStored);
            await handleRefreshToken(refreshTokenStored)
            setUser(userStored)

            // Realiza a Validação do login após 30 minutos
            setTimeout(() => {
                checkLogin()
            }, 60000 * 30)

        } catch (e) {
            logout()
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 5000)
        }
    },[handleRefreshToken])

    useEffect(() => {
        checkLogin()
    }, [checkLogin])

    return (
        <AuthContext.Provider
            value={{ signed: user, login, logout, user, loading, alert, redirectTo, setRedirectTo }}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    return useContext(AuthContext);
}
