import React, { createContext, useContext, useEffect, useState, useRef } from 'react';

import {
    getGeral,
    getFazenda,
    putTriagem,
    postBalancaSaida,
    getPainel,
    getTriagemDash,
    getTriagemDashDetail
} from '../services/SecService';
import {
    faTruck,
    faUserTie,
    faCar,
    faBox,
    faIndustry,
    faLightbulb,
    faSignInAlt,
    faSignOutAlt,
    faCheckDouble
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./auth.context";

var fill = [
    { id: 1, name: 'Cliente', value: undefined, icon: faUserTie, search: false },
    { id: 2, name: 'Produto', value: undefined, icon: faBox, search: false },
    { id: 3, name: 'Transportadora', value: undefined, icon: faTruck, search: false },
    { id: 4, name: 'Fornecedor', value: undefined, icon: faIndustry, search: false },
    { id: 5, name: 'Placa', value: undefined, icon: faCar, search: false },
    { id: 6, name: 'Situação', value: undefined, icon: faLightbulb, search: false },
    { id: 8, name: 'Tipo', value: undefined, icon: faTruck, search: false },
]

const GeralContext = createContext({
    geral: null,
    local: null,
    fazenda: { "nomeFazenda": null },
    idGuarita: null,
    chooseSelectedGuarita: null,
});

export const GeralProvider = ({ children }) => {
    const timeoutRef = useRef(null);

    const [geral, setGeral] = useState([]);
    const [filter, setFilter] = useState({});
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [totalLines, setTotalLines] = useState(0);
    const [fazendaPainel, setFazendaPainel] = useState();
    const [resumeGeral, setResumeGeral] = useState(
        [
            { title: 'Aguardando Entrada', subtitle: '(Veiculos fora do Pátio)', icon: faSignInAlt, total: 0 },
            { title: 'Veículos no Pátio', subtitle: '(No Pátio, Carregando e Aguardando NF)', icon: faSignOutAlt, total: 0 },
            { title: 'Aguardando Carregamento', subtitle: '(NF-S)', icon: faTruck, total: 0 },
            { title: 'Carregamentos Concluídos', subtitle: '', icon: faCheckDouble, total: 0 }
        ]
    );
    const [disabled, setDisabled] = useState(true)
    const [geralAlt, setGeralAlt] = useState(geral);
    const [fazenda, setFazenda] = useState([]);
    const [selectFazenda, setSelectFazenda] = useState(0);
    const [loadingGeral, setLoadingGeral] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({ msg: 'success', desc: undefined })
    const [rows, setRows] = useState([]);
    const [period, setPeriod] = useState();
    const [embaqueProducao, setEmbaqueProducao] = useState([]);
    const [embaqueProducaoResumo, setEmbaqueProducaoResumo] = useState();
    const [embaqueProducaoDetail, setEmbaqueProducaoDetail] = useState([]);
    const [embaqueProducaoResumoDetail, setEmbaqueProducaoResumoDetail] = useState();
    const { loading } = useAuth()

    const getEmbarqueProducao = async () => {
        try {
            const result = await getTriagemDash()
            setEmbaqueProducao(result.dados)
            setEmbaqueProducaoResumo(result.resumo)
        } catch (e) {
            console.log(e)
        }
    }


    const getEmbarqueProducaoDetail = async () => {
        try {
            const result = await getTriagemDashDetail()
            setEmbaqueProducaoDetail(result.dados)
            setEmbaqueProducaoResumoDetail(result.resumo)
        } catch (e) {
            console.log(e)
        }
    }

    const getSelectFazenda = (props) => {
        setSelectFazenda(props)
        localStorage.setItem('@SCHAuth:fazenda', props)
    }

    const getLoadingGeral = (load) => {
        setLoadingGeral(load);
    }

    const syncGeral = async () => {
        setRefresh(true)
        getLoadingGeral(true);
        setDisabled(true)
        const pagination = {
            page: page,
            perPage: perPage,
            periodo: period,
            ...filter
        }

        try {
            const response = await getGeral(pagination);
            const dados = response.dados
            setGeral(dados)
            setTotalLines(response.lines)
            const resume = response.resume
            setResumeGeral(
                [
                    { title: 'Aguardando Entrada', subtitle: '(Veiculos fora do Pátio)', icon: faSignInAlt, total: resume?.qtdEntrada },
                    { title: 'Veículos no Pátio', subtitle: '(No Pátio, Carregando e Aguardando NF)', icon: faSignOutAlt, total: resume?.qtdNoPatio },
                    { title: 'Aguardando Carregamento', subtitle: '(NF-S)', icon: faTruck, total: resume?.qtdAguardandoNF },
                    { title: 'Carregamentos Concluídos', subtitle: '', icon: faCheckDouble, total: resume?.qtdFinalizada }
                ]
            )
            setTimeout(() => setRefresh(false), 3000);
        } catch (e) {
            getLoadingGeral(false);
            setRefresh(false)
        } finally {
            setTimeout(() => setDisabled(false), 2000)
        }
    }

    const syncGeralAlt = async () => {
        try {
            if (fazendaPainel) {
                const response = await getPainel(fazendaPainel);
                const dados = response.dados
                setGeralAlt(dados)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const syncFazenda = async () => {
        try {
            const response = await getFazenda();
            const dados = response.dados
            setFazenda(dados)
        } catch (e) {
            setRefresh(false);
        }
    }

    const attEProducao = async (dados, valid) => {
        setRefresh(true);
        try {
            await putTriagem(dados).then(item => {
                if (item.type === 'Error') {
                    setAlert({ msg: 'error', desc: item.message })
                    setOpen(true)
                    setTimeout(() => setOpen(false), 3000)
                } else {
                    setAlert({ msg: 'success', desc: item.message })
                    setOpen(true)
                    setTimeout(() => setOpen(false), 3000)
                }
            });
            syncGeral();
        } catch (e) {
            setRefresh(false);
            setAlert({ msg: 'error', desc: e })
        }
    }

    const finalizarTriagem = async (dados) => {
        setRefresh(true);

        const user = JSON.parse(localStorage.getItem('@SCHAuth:user'));

        var props = {};
        props.idTriagem = dados['idTriagem'];
        props.notaFiscal = dados['notaFiscal'];
        props.idUser = user.dados.id;

        try {
            await postBalancaSaida(props).then(item => {
                if (item.type === 'Error') {
                    setAlert({ msg: 'error', desc: item.message })
                    setOpen(true)
                    setTimeout(() => setOpen(false), 3000)
                } else {
                    setAlert({ msg: 'success', desc: item.message })
                    setOpen(true)
                    setTimeout(() => setOpen(false), 3000)
                    syncGeral();
                }
            });

        } catch (e) {
            setRefresh(false);
            setAlert({ msg: 'error', desc: e })
        }
    }

    const filterCarga = async () => {
        const pagination = {
            page: page,
            perPage: perPage,
            periodo: period,
            ...filter
        }
        try {
            const response = await getGeral(pagination);
            const dados = response.dados
            setRows(dados)
            setTotalLines(response.lines)
            const resume = response.resume
            setResumeGeral(
                [
                    { title: 'Aguardando Entrada', subtitle: '(Veiculos fora do Pátio)', icon: faSignInAlt, total: resume?.qtdEntrada },
                    { title: 'Veículos no Pátio', subtitle: '(No Pátio, Carregando e Aguardando NF)', icon: faSignOutAlt, total: resume?.qtdNoPatio },
                    { title: 'Aguardando Carregamento', subtitle: '(NF-S)', icon: faTruck, total: resume?.qtdAguardandoNF },
                    { title: 'Carregamentos Concluídos', subtitle: '', icon: faCheckDouble, total: resume?.qtdFinalizada }
                ]
            )
        } catch (e) {
            console.log(e)
        } finally {
            setTimeout(() => getLoadingGeral(false), 1500);
        }
    }

    const submitSearch = async event => {
        event.preventDefault();
        const label = event.nativeEvent.target[0].name === undefined ? event.nativeEvent.target.name : event.nativeEvent.target[0].name;
        var value;
        var search;

        var obj = filter
        switch (label) {
            case 'Cliente':
                value = event.currentTarget.Cliente.value;
                search = true;
                obj.Cliente = value
                break;
            case 'Placa':
                value = (event.currentTarget.Placa.value).toUpperCase().replace("-", '');
                search = true;
                obj.Placa = value
                break;
            case 'Produto':
                value = event.currentTarget.Produto.value;
                search = true;
                obj.Produto = value
                break;
            case 'Transportadora':
                value = event.currentTarget.Transportadora.value;
                search = true;
                obj.Transportadora = value
                break;
            case 'Fornecedor':
                value = event.currentTarget.Fornecedor.value;
                search = true;
                obj.Fornecedor = value
                break;
            case 'Situação':
                value = event.target.value;
                search = true;
                obj['Situação'] = value
                break;
            case 'Tipo':
                value = event.target.value;
                search = true;
                obj.Tipo = value
                break;
            default:
                console.log('Filtro não Encontrado!!')
        }

        const find1 = fill.filter(x => x.name === label)[0]
        find1['value'] = undefined;
        find1['search'] = false;

        const find = fill.filter(x => x.name === label)[0]
        find['value'] = value
        find['search'] = search
        filterCarga()
        setPage(0)
        setFilter(obj)
    }

    const removeSearch = (props) => {
        const label = props.name
        var value;
        var search;
        var obj = filter
        switch (label) {
            case 'Cliente':
                value = undefined;
                search = false;
                delete obj.Cliente
                break;
            case 'Placa':
                value = undefined;
                search = false;
                delete obj.Placa
                break;
            case 'Produto':
                value = undefined;
                search = false;
                delete obj.Produto
                break;
            case 'Transportadora':
                value = undefined;
                search = false;
                delete obj.Transportadora
                break;
            case 'Fornecedor':
                value = undefined;
                search = false;
                delete obj.Fornecedor
                break;
            case 'Situação':
                value = undefined;
                search = false;
                delete obj['Situação']
                break;
            case 'Tipo':
                value = undefined;
                search = false;
                delete obj.Tipo
                break;
            default:
                console.log('Filtro não Encontrado!!')
        }

        const find = fill.filter(x => x.name === label)[0]
        find['value'] = value;
        find['search'] = search;

        filterCarga()
        setPage(0)
        setFilter(obj)
    }

    useEffect(() => {
        getEmbarqueProducao()
        // eslint-disable-next-line
    }, [selectFazenda])

    useEffect(() => {
        getEmbarqueProducaoDetail() 
        // eslint-disable-next-line
    }, [selectFazenda])

    useEffect(() => {
        // const token = localStorage.getItem('@SCHAuth:token')
        syncGeral()
            .then(() => console.log('Cadastros Sincronizados'))
        // eslint-disable-next-line
    }, [page, perPage, selectFazenda, period, filter])

    useEffect(() => {
        syncFazenda()
            .then(() => console.log('Fazendas Sincronizadas'))
        // eslint-disable-next-line
    }, [loading])

    useEffect(() => {
        filterCarga()
        // eslint-disable-next-line
    }, [geral]);

    useEffect(() => {
        syncGeralAlt()
            .then(() => console.log('Geral Sincronizado'))
        // eslint-disable-next-line
    }, [geral])

    useEffect(() => {
        filterCarga()
        // eslint-disable-next-line
    }, [filter])

    /* Releod de informações */
    useEffect(() => {
        // Atualização geral a cada 1 minuto
        const interval = setInterval(() => {
            syncGeral();
            // eslint-disable-next-line
        }, 60000);
    
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [timeoutRef]);

    useEffect(() => {
        getEmbarqueProducao()
        // eslint-disable-next-line
    }, [geral]);


    useEffect(() => {
        getEmbarqueProducaoDetail()
        // eslint-disable-next-line
    }, [embaqueProducao]);

    return (
        <GeralContext.Provider
            value={{
                syncGeralAlt,
                getLoadingGeral,
                getSelectFazenda,
                attEProducao,
                submitSearch,
                removeSearch,
                finalizarTriagem,
                setPage,
                setPerPage,
                setTotalLines,
                setPeriod,
                setFazendaPainel,
                getEmbarqueProducao,
                getEmbarqueProducaoDetail,
                embaqueProducao,
                embaqueProducaoResumo,
                embaqueProducaoDetail,
                embaqueProducaoResumoDetail,
                resumeGeral,
                page,
                perPage,
                totalLines,
                disabled,
                geral,
                geralAlt,
                fazenda,
                selectFazenda,
                loadingGeral,
                refresh,
                open,
                alert,
                rows,
                fill,
                period,
                filter,
                fazendaPainel
            }}
        >
            {children}
        </GeralContext.Provider>
    );
};

export function useGeral() {
    const context = useContext(GeralContext);
    return context;
}
