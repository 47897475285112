import React, { useState } from "react";
import 'date-fns';
import clsx from 'clsx';
import {
    Paper,
    IconButton,
    Modal,
    Box,
    Typography,
    Divider,
    Button,
    Select,
    FormHelperText,
    MenuItem,
    FormControl,

} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHandPointUp,
    faTimes
} from "@fortawesome/free-solid-svg-icons";

import Style from "../../../styles/Style";

const useStyles = Style();

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const SearchData = ({ props }) => {

    const classes = useStyles();
    var inicial = new Date();
    inicial = new Date(inicial.setHours(0))
    inicial = new Date(inicial.setMinutes(0))
    inicial = new Date(inicial.setSeconds(0))

    var fim = new Date();
    fim = new Date(fim.setHours(23))
    fim = new Date(fim.setMinutes(59))
    fim = new Date(fim.setSeconds(59))

    const [tipoDate, setTipoDate] = useState('');

    const [inicio, setInicio] = useState(inicial);
    const [final, setFinal] = useState(fim);

    const id = props.id;
    const open = props.open;
    const anchorEl = props.anchorEl;
    const handleClose = props.handleClose;
    const submitSearch = props.submitSearch;
    const filterInput = props.filterInput;

    // const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setTipoDate(event.target.value);
    };

    const handleCloseAlt = () => {
        handleClose();
    }

    const handleSubmit = (event, per) => {
        event.preventDefault();
        event.data = { inicial: inicio, final: final, periodo: per }
        submitSearch(event)

        var inicial = new Date();
        inicial = new Date(inicial.setHours(0))
        inicial = new Date(inicial.setMinutes(0))
        inicial = new Date(inicial.setSeconds(0))

        var fim = new Date();
        fim = new Date(fim.setHours(23))
        fim = new Date(fim.setMinutes(59))
        fim = new Date(fim.setSeconds(59))

        setInicio(inicial)
        setFinal(fim)
        handleClose();
    }

    const handleChangeDate = (props) => {
        var inicial = props;
        inicial = new Date(inicial.setHours(0))
        inicial = new Date(inicial.setMinutes(0))
        inicial = new Date(inicial.setSeconds(0))

        var final = props;
        final = new Date(final.setHours(23));
        final = new Date(final.setMinutes(59));
        final = new Date(final.setSeconds(59));

        setInicio(inicial)
        setFinal(final)
    }

    const handleChangeDateInicio = (props) => {
        var inicial = props;
        inicial = new Date(inicial.setHours(0))
        inicial = new Date(inicial.setMinutes(0))
        inicial = new Date(inicial.setSeconds(0))

        setInicio(inicial)
    }

    const handleChangeDateFinal = (props) => {
        var data = props;
        data = new Date(data.setHours(23));
        data = new Date(data.setMinutes(59));
        data = new Date(data.setSeconds(59));

        setFinal(data)
    }

    return (
        <Modal
            id={id}
            open={open}
            anchorEl={anchorEl}
            className={classes.modalAlt}
        >
            <Paper
                className={classes.modalLista}
                variant={'outlined'}
            >
                <Box m={1} p={1} className={classes.modalLibHead}>
                    <Box>
                        <Typography variant={'h6'}>
                            {'Buscar ' + filterInput.name}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleCloseAlt} className={classes.closeIcon}>
                            <FontAwesomeIcon icon={faTimes} size={'sm'} />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />

                <div className={classes.inputSearchList}>
                    <FormControl className={classes.inputSelect} variant={'outlined'}>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={tipoDate}
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>Data exata!</MenuItem>
                            <MenuItem value={2}>De até!</MenuItem>
                        </Select>
                        <FormHelperText>Selecione uma opção!</FormHelperText>
                    </FormControl>
                </div>
                <TabPanel value={tipoDate} index={''} >
                    <div className={classes.boxData}>
                        <Typography variant={"overline"} color={"textSecondary"}>
                            <FontAwesomeIcon icon={faHandPointUp} size={'3x'} />
                        </Typography>
                        <Typography variant={"overline"} color={"textSecondary"}>
                            Selecione um tipo de data!
                        </Typography>
                    </div>
                </TabPanel>
                <form onSubmit={e => handleSubmit(e, false)} className={classes.inputSearchList} >
                    <TabPanel value={tipoDate} index={1}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.inputSelectDate}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label="Data inicial"
                                value={inicio}
                                onChange={date => handleChangeDate(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                name={'Data'}
                                inputVariant="outlined"
                            />
                        </MuiPickersUtilsProvider>
                        <Button
                            // disabled={valida(value.notaFiscal)}
                            type="submit"
                            variant="outlined"
                            fullWidth
                            className={clsx(classes.submitAlt, {
                                [classes.submitDisabled]: false
                            })}
                        >
                            Buscar
                        </Button>
                    </TabPanel>
                </form>
                <form onSubmit={e => handleSubmit(e, true)} className={classes.inputSearchList} >
                    <TabPanel value={tipoDate} index={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.inputSelectDate}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label="Data inicial"
                                value={inicio}
                                onChange={date => handleChangeDateInicio(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                inputVariant="outlined"
                                name={'Data'}
                            />
                            <KeyboardDatePicker
                                className={classes.inputSelectDate}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label="Data final"
                                value={final}
                                onChange={date => handleChangeDateFinal(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                inputVariant="outlined"
                                name={'Data1'}
                            />
                        </MuiPickersUtilsProvider>
                        <Button
                            // disabled={valida(value.notaFiscal)}
                            type="submit"
                            variant="outlined"
                            fullWidth
                            className={clsx(classes.submitAlt, {
                                [classes.submitDisabled]: false
                            })}
                        >
                            Buscar
                        </Button>
                    </TabPanel>
                </form>
            </Paper>
        </Modal>
    )
}

export default SearchData;
