import { Paper, Grid, Typography, Chip, Button, Badge } from "@material-ui/core";
import { useGeral } from "../../../context/geral.context";
import { ComponentDetailPage } from "./style/Dashboard.styte";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";

const EmbaqueProducaoViewDetail = () => {
  const [total, setTotal] = useState(0);
  const [openClient, setOpenClient] = useState({ index: null, indexItem: null });
  const [expanded, setExpanded] = useState(false);

  const mainClass = ComponentDetailPage();
  const classe = mainClass();

  const {
    embaqueProducaoDetail,
    embaqueProducaoResumoDetail
  } = useGeral()

  const handleOpeneCliente = (index, indexItem) => {
    if (index === openClient.index && indexItem === openClient.indexItem) {
      setOpenClient({ index: null, indexItem: null })
    } else {
      setOpenClient({ index: index, indexItem: indexItem })
    }
  }

  useEffect(() => {
    if (embaqueProducaoResumoDetail) {
      setTotal(embaqueProducaoResumoDetail?.aguardando + 
        embaqueProducaoResumoDetail?.aguardando_carregamento +
        embaqueProducaoResumoDetail?.aguardando_nf + 
        embaqueProducaoResumoDetail?.concluido +
        embaqueProducaoResumoDetail?.aguardando_saida)
    }
  }, [embaqueProducaoResumoDetail])

  return (
    <div>
      <Grid
        container
        direction="row"
        spacing={0}
      >
        <Grid item xs={3} sm={3} md={3} />
        <Grid item xs={1} sm={1} md={1} >
          <Paper className={classe.head} elevation={1}>
            <Typography variant="button" className={classe.titleHead}>
              Aguardando Entrada
            </Typography>
            <Typography variant="h6">
              {embaqueProducaoResumoDetail?.aguardando}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Paper className={classe.head} elevation={1}>
            <Typography variant="button" className={classe.titleHead}>
              Aguardando Carregamento
            </Typography>
            <Typography variant="h6">
              {embaqueProducaoResumoDetail?.aguardando_carregamento}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Paper className={classe.head} elevation={1}>
            <Typography variant="button" className={classe.titleHead}>
              Aguardando NF-S
            </Typography>
            <Typography variant="h6">
              {embaqueProducaoResumoDetail?.aguardando_nf}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Paper className={classe.head} elevation={1}>
            <Typography variant="button" className={classe.titleHead}>
              Aguardando Saída
            </Typography>
            <Typography variant="h6">
              {embaqueProducaoResumoDetail?.aguardando_saida}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Paper className={classe.head} elevation={1}>
            <Typography variant="button" className={classe.titleHead}>
              Concluídos (hoje)
            </Typography>
            <Typography variant="h6">
              {embaqueProducaoResumoDetail?.concluido}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Paper className={classe.head} elevation={1}>
            <Typography variant="button" className={classe.titleHead}>
              Total
            </Typography>
            <div className={classe.titleColor} />
            <Typography variant="h6">
              {total}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3} sm={3} md={3} >
          <div className={classe.mainButtonExpanded}>
            <Button
              onClick={() => setExpanded(!expanded)}
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faExpandAlt} />}
              className={classe.buttonExpanded}
              color="primary"
              id={'nao-imprimir'}
            >
              Todos os Clientes
            </Button>
          </div>
        </Grid>
      </Grid>
      {
        embaqueProducaoDetail.map((x, index) => (
          <Paper key={index} className={classe.main} variant={"outlined"} id={'paper-break'}>
            <div className={classe.body}>
              <div className={classe.bodyTitle}>
                <Typography variant="button" className={classe.title}>
                  {x?.produto}
                </Typography>
              </div>
              {
                x?.body?.map((item, indexItem) => (
                  <div key={indexItem} className={classe.bodyComponent}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      spacing={1}
                      className={classe.line}
                    >
                      <Grid item xs={3} sm={3} md={3} >
                        <Paper className={classe.component} variant={"outlined"}>
                          <Typography variant="button" className={classe.textBody}>
                            {item?.fazenda}
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} >
                        <Paper className={classe.componentBody} variant={"outlined"}>
                          <Typography variant="button" className={classe.textBody}>
                            {item?.aguardando}
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} >
                        <Paper className={classe.componentBody} variant={"outlined"}>
                          <Typography variant="button" className={classe.textBody}>
                            {item?.aguardando_carregamento}
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} >
                        <Paper className={classe.componentBody} variant={"outlined"}>
                          <Typography variant="button" className={classe.textBody}>
                            {item?.aguardando_nf}
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} >
                        <Paper className={classe.componentBody} variant={"outlined"}>
                          <Typography variant="button" className={classe.textBody}>
                            {item?.aguardando_saida}
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} >
                        <Paper className={classe.componentBody} variant={"outlined"}>
                          <Typography variant="button" className={classe.textBody}>
                            {item?.concluido}
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} >
                        <Paper className={classe.componentBodyTotal} variant={"outlined"}>
                          <Typography variant="button" className={classe.textBodyTotal}>
                            {item?.aguardando + item?.aguardando_carregamento + item?.aguardando_nf + item?.concluido + item?.aguardando_saida}
                          </Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} >
                        <Button
                          fullWidth
                          variant={"outlined"}
                          id={'nao-imprimir'}
                          onClick={() => handleOpeneCliente(index, indexItem)}
                        >
                          <Badge badgeContent={item?.cliente?.length} color="primary" style={{ width: '100%' }}>
                            Visualizar Clientes
                          </Badge>
                        </Button>
                        <Paper
                          className={classe.component}
                          variant={"outlined"}
                          style={{ display: (openClient.index === index && openClient.indexItem === indexItem) || expanded ? 'flex' : 'none' }}
                          id={'imprimir'}
                        >
                          {
                            item?.cliente?.map((cliente, index) => (
                              <Chip
                                key={index}
                                size="small"
                                elevation={2}
                                label={cliente?.total + ' ' + (cliente?.nome.length > 50 ? cliente?.nome.substring(0, 50) + '...' : cliente?.nome)}
                                className={classe.chipBody}
                              />
                            ))
                          }
                        </Paper>
                      </Grid>
                    </Grid >
                  </div >
                ))
              }
              <div className={classe.totalBody} elevation={2}>
                <Typography variant="subtitle1" className={classe.totalText} >
                  Total por Produto: {x?.total}
                </Typography>
                <Typography variant="subtitle1" className={classe.totalText} >
                  Aguardando Carregamento: {x?.total_carregamento}
                </Typography>
              </div>
            </div>
          </Paper>
        ))
      }
    </div >
  )
}

export default EmbaqueProducaoViewDetail;