import React, { useState } from "react";
import {
    Typography,
    Paper,
    Chip,
    Grid,
    Box,
    Tooltip
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

import Style from "../../styles/Style";
import { useGeral } from "../../context/geral.context";
import SearchModal from "./modal/SearchModal";
import ChipList from "./ChipList";

const useStyles = Style();

const FiltersBar = ({ props }) => {
    const classes = useStyles();
    const { submitSearch, removeSearch, fill } = useGeral();
    const [filterInput, setFilterInput] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInput = (event, props) => {
        handleClick(event)
        setFilterInput(props)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const title = props.title;

    return (
        <Paper
            className={classes.rootDashHead}
            elevation={1}
        >
            <Grid container>
                <Grid item xs={12} sm={12} md={2} className={classes.gridHeadLeft}>
                    <Typography color="textSecondary" variant="h6" component="h2">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.gridHeadCenter}>
                    <Box >
                        {
                            fill.filter(x => x.search).map(l => (
                                <ChipList props={{ data: l, rm: removeSearch }} />
                            ))
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.gridHeadRight}>
                    <Box >
                        {
                            fill.map(l => (
                                <Tooltip title={"Filtrar " + l.name}>
                                    <Chip
                                        key={l.id}
                                        label={l.name}
                                        deleteIcon={<FontAwesomeIcon icon={faPlusCircle} className={classes.colorChipPrimaryText} />}
                                        onClick={(e) => handleInput(e, l)}
                                        onDelete={(e) => handleInput(e, l)}
                                        size={'small'}
                                        className={classes.chipBoxAlt}
                                    />
                                </Tooltip>
                            ))
                        }
                        <SearchModal props={{
                            id: id,
                            open: open,
                            anchorEl: anchorEl,
                            handleClose: handleClose,
                            submitSearch: submitSearch,
                            filterInput: filterInput,
                        }} />
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default FiltersBar;