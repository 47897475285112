import React, { useState } from 'react';

import { Tabs, Tab, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import DashEmbarque from './HomeDashboard';
import DashEmbarqueDetail from './HomeDashboardDetail';
import HomeAguardandoNF from './HomeAguardandoNF';
import Dash from './Dashboard';
import Color from '../../styles/Color';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const HomePage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        id='nao-imprimir'
        style={{
          border: '1px black solid',
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderColor: Color.inputLight,
          background: Color.inputLight
        }}
      >
        <Tab
          label="Embarque Detalhado"
          {...a11yProps(0)}
          id='nao-imprimir'
          style={{
            border: '1px black solid',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderColor: Color.inputLight,
            marginTop: 5,
            borderBottom: 'none',
            background: value === 0 ? Color.secundaryText : Color.inputLight
          }}
        />
        <Tab
          label="Resumo de Embarque"
          {...a11yProps(1)}
          id='nao-imprimir'
          style={{
            border: '1px black solid',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderColor: Color.inputLight,
            marginTop: 5,
            borderBottom: 'none',
            background: value === 1 ? Color.secundaryText : Color.inputLight
          }}
        />
        <Tab
          label="Todas as Entradas"
          {...a11yProps(2)}
          id='nao-imprimir'
          style={{
            border: '1px black solid',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderColor: Color.inputLight,
            marginTop: 5,
            borderBottom: 'none',
            background: value === 2 ? Color.secundaryText : Color.inputLight
          }}
        />
        <Tab
          label="Aguardando NF"
          {...a11yProps(3)}
          id='nao-imprimir'
          style={{
            border: '1px black solid',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            borderColor: Color.inputLight,
            marginTop: 5,
            borderBottom: 'none',
            background: value === 3 ? Color.secundaryText : Color.inputLight
          }}
        />

      </Tabs>
      <TabPanel value={value} index={0}>
        <DashEmbarqueDetail />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DashEmbarque />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Dash />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <HomeAguardandoNF />
      </TabPanel>
    </div>
  );
}

export default HomePage;