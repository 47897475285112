import React, {useEffect} from 'react'
import {
    Breadcrumbs,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import {Link, useNavigate} from "react-router-dom";

import TablePaginationActions from "./components/TablePaginationActions";
import {getBlockedDrivers} from "../../../services/blacklist";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
    componentContainer: {
        marginLeft: theme.spacing(20),
        marginRight: theme.spacing(20)
    },
    linkStyle: {
        textDecoration: "none", color: 'black'
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    flexHeader: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerInfo: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    headerActions: {
        display: 'flex',
        alignItems: 'center',

    },
    buttonAction: {
        color: '#FEFEFE',
        background: '#81411D',
        boxShadow: "rgb(181 134 110/ 50%) 0px 8px 16px 0px"
    }
}))


const BlacklistIndexScreen = () => {

    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [drivers, setDrivers] = React.useState([])

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, drivers.length - page * rowsPerPage);
    const navigate = useNavigate()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const handleGetBlockedDrivers = async () => {
            const blockedDrivers = await getBlockedDrivers(page, rowsPerPage).then(res => res.data)
            setDrivers(blockedDrivers)
        }

        handleGetBlockedDrivers()
            .catch(e => console.log(e))
    }, [page, rowsPerPage])

    return (
        <div className={classes.componentContainer}>
            <div className={classes.flexHeader}>
                <div className={classes.headerInfo}>
                    <h2>Blacklist</h2>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>

                        <Link color="inherit" to="/" className={classes.linkStyle}>
                            Home
                        </Link>

                        <Typography color="textPrimary">Blacklist</Typography>
                    </Breadcrumbs>
                </div>
                <div className={classes.headerActions}>
                    <Button variant="contained" className={classes.buttonAction}
                            startIcon={<FontAwesomeIcon icon={faPlus}/>}
                            onClick={() => navigate('/black-list/add-driver-to-blacklist')}
                    >
                        Adicionar Motorista
                    </Button>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                CPF
                            </TableCell>
                            <TableCell align="left">
                                Vencimento
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 ? drivers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : drivers).map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.cpf}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {new Date(row.limit).toLocaleDateString('pt-BR')}
                                </TableCell>
                            </TableRow>))}

                        {emptyRows > 0 && (<TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={6}/>
                        </TableRow>)}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                                colSpan={3}
                                count={drivers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'}, native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    )

}


export default BlacklistIndexScreen
