import React, { useState } from "react";
import {
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Chip,
    Box,
    Tooltip,
    LinearProgress,
    IconButton
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTruck,
    faInfoCircle,
    faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

import Style from "../../../styles/Style";
import Color from "../../../styles/Color";
import { useGeral } from "../../../context/geral.context";
import { chipColor, tipoView } from "../../../util/TabCarga";
import PaginationBar from "../../geral/PaginationBar";
import ModalDetailTriagemComponent from "./detail/ModelDetailTriagem.component";

const useStyles = Style();

export default function TabCarga() {
    const [modal, setModal] = useState(false);
    const [propsModal, setPropsModal] = useState();
    const idFazenda = localStorage.getItem('@SCHAuth:fazenda') === '0' ? null : localStorage.getItem('@SCHAuth:fazenda');

    const classes = useStyles();
    const { rows, page, perPage, totalLines, setPage, setPerPage, disabled, loadingGeral } = useGeral();

    const rowsFilter = rows.filter(x => x.status !== 'Cancelado')

    const emptyRows = perPage - Math.min(perPage, totalLines - page * perPage);

    const emp = Array.from(Array(emptyRows).keys());

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openModal = (props) => {
        setModal(true)
        setPropsModal(props)
    }

    return (
        <div>
            <Paper className={classes.rootDashAlt} elevation={1}>
                {
                    loadingGeral ? (
                        <LinearProgress />
                    ) : (
                        <></>
                    )
                }
                <TableContainer className={classes.paperTable}>
                    <Table className={classes.table} aria-label="custom pagination table" size="small">
                        <TableBody>
                            {rowsFilter.map((row) => (
                                <TableRow hover key={row.idTriagem}>
                                    {
                                        !idFazenda ? (
                                            <TableCell component="th" scope="row" >
                                                <Typography color="textSecondary" >
                                                    {row.nomeFazenda}
                                                </Typography>
                                            </TableCell>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <TableCell component="th" scope="row">
                                        <FontAwesomeIcon icon={faInfoCircle} color={Color.primary} size={'lg'} />
                                    </TableCell>
                                    <TableCell >
                                        <Box className={classes.tableAction} >
                                            <Tooltip title={row.motorista} placement="top" arrow>
                                                <Typography color="textSecondary">
                                                    <b>Motorista:</b> {(row.motorista).length > 10 ? (row.motorista).substring(0, 10) + '...' : row.motorista}
                                                </Typography>
                                            </Tooltip>
                                            <Tooltip title={'Veículo está'} placement="top" arrow>
                                                <Chip label={row.status} style={chipColor(row.status)} size="small" />
                                            </Tooltip>
                                        </Box>
                                        <Box className={classes.tableAction} >
                                            <Typography color="textSecondary">
                                                <b>Placa:</b> {(row.placa).substring(0, 3) + "-" + (row.placa).substring(3, 8)}
                                            </Typography>
                                            <Box hidden={row.idProduto === 1}>
                                                <Tooltip title={'Produto'} placement="bottom" arrow>
                                                    <Chip label={row.nomeProduto} color={'primary'} className={classes.inputSearchIcon} size="small" />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell >
                                        <Typography variant={"subtitle2"} className={classes.fontTab}>
                                            <Box hidden={row.idCliente === 1}>
                                                <Tooltip title={row.nomeCliente} placement="top" arrow>
                                                    <Typography color="textSecondary">
                                                        <b>Cliente:</b> {(row.nomeCliente).length > 10 ? (row.nomeCliente).substring(0, 10) + '...' : row.nomeCliente} <br />
                                                    </Typography>
                                                </Tooltip>
                                            </Box>
                                            <Box hidden={row.idTransportadora === 1}>
                                                <Tooltip title={row.nomeTransportadora} placement="top" arrow>
                                                    <Typography color="textSecondary">
                                                        <b>Transportadora:</b> {(row.nomeTransportadora).length > 10 ? (row.nomeTransportadora).substring(0, 10) + '...' : row.nomeTransportadora}
                                                    </Typography>
                                                </Tooltip>
                                            </Box>
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <FontAwesomeIcon icon={faTruck} color={Color.antColor} size={'lg'} />
                                    </TableCell>
                                    <TableCell >
                                        <Typography color="textSecondary" variant="subtitle2">
                                            <b>Tipo:</b> {tipoView(row.tipo)}<br />
                                            <b>Triagem:</b> {row?.dataTriagem ? row?.dataTriagem.substring(0, 16) : ''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <FontAwesomeIcon icon={faTruck} color={Color.selection} size={'lg'} />
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary" variant="subtitle2">
                                            <b>Guarita:</b> {row.nomeGuaritaEnt} <br />
                                            <b>Entrada:</b> {row?.dataEntrada ? row?.dataEntrada.substring(0, 16) : ''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <FontAwesomeIcon icon={faTruck} color={Color.closeColor} size={"lg"} />
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary" variant="subtitle2">
                                            <b>Guarita:</b> {row.nomeGuaritaSai} <br />
                                            <b>Saída:</b> {row?.dataSaida ? row?.dataSaida.substring(0, 16) : ''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="textSecondary" variant="subtitle2">
                                            <IconButton
                                                onClick={() => openModal(row)}
                                            >
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </IconButton>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}

                            {emptyRows > 0 && (
                                emp.map((l, i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={10}>
                                            <Typography variant={"subtitle2"} className={classes.fontTab}>
                                                |
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <PaginationBar
                label={'Linhas por Página'}
                listRows={[5, 10]}
                rowsPage={perPage}
                len={totalLines}
                changePage={handleChangePage}
                changeRowsPage={handleChangeRowsPerPage}
                page={page}
                disabled={disabled}
            />
            <ModalDetailTriagemComponent open={modal} data={propsModal} onClose={setModal} />
        </div >
    )
}
