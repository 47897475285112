import { Dialog, AppBar, Toolbar, Typography, IconButton, Grid } from "@material-ui/core"
import Style from "../../../../styles/Style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DetailDataComponent from "./DetailData.component";
import DetailAllComponent from "./DetailAll.component";


const useStyles = Style();

const ModalDetailTriagemComponent = ({ open, data, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={open}>
      <AppBar className={classes.appBar} variant={'outlined'}>
        <Toolbar className={classes.toolbarEdit}>
          <Typography variant="h6" className={classes.title}>
            {"Triagem: " + data?.placa}
          </Typography>
          <IconButton edge="start" aria-label="close" onClick={() => onClose(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.root}>
        <Grid item xs={null} sm={null} md={2} />
        <Grid item xs={12} sm={12} md={4} style={{ marginTop: 100 }}>
          <DetailDataComponent data={data} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} style={{ marginTop: 100 }}>
          <DetailAllComponent data={data} />
        </Grid>
        <Grid item xs={null} sm={null} md={2} />
      </Grid>
    </Dialog >
  )
}

export default ModalDetailTriagemComponent;