import React from 'react'
import { Grid } from '@material-ui/core';
import SelectFazenda from "../geral/SelectFazenda";
import TabCarga from "./components/TabCarga";
import CardsDash from "./components/CardsDash";
import FiltersBar from "../geral/FiltersBar";
import CardSLAComponent from './components/CardSLA.component';
import SelectPeriodComponent from './components/SelectPeriod.component';

function Dash() {

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                <Grid item xs={12} sm={12} md={4}>
                    <SelectPeriodComponent />
                </Grid>
                <Grid item xs={false} sm={12} md={6} />
                <Grid item xs={12} sm={12} md={2}>
                    <SelectFazenda />
                </Grid>
            </Grid>
            <CardsDash />
            <CardSLAComponent />
            <FiltersBar props={{ title: 'Movimentação Pátio!' }} />
            <TabCarga />
        </div>
    )
}

export default Dash;
