import React from 'react'
import { Grid } from '@material-ui/core'
import DashCad from "./components/DashCad"

function Cadastros() {

    return (
        <Grid
            container
            style={{ padding: 15 }}
        >
            <Grid item xs={12} sm={12} md={2} />
            <Grid item xs={12} sm={12} md={8}>
                <DashCad />
            </Grid>
            <Grid item xs={12} sm={12} md={2} />
        </Grid>
    )
}

export default Cadastros;
