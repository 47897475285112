import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../../screens/home/Home";
import Carga from "../../screens/triagem/Carga";
import LiberacaoEntrada from "../../screens/liberacao/LiberacaoEntrada";
import Liberacao from "../../screens/liberacao/Liberacao";
import BlacklistIndexScreen from "../../screens/blacklist/index/BlacklistIndex.screen";
import AddDriverToBlacklist from "../../screens/blacklist/add-driver-to-blacklist/AddDriverToBlacklist.screen";
import PainelLiberacao from "../../screens/painel/PainelLiberacao";
import Cadastros from "../../screens/cadastros/Cadastros";
import LogPage from "../../screens/logs/Logs.page";

function IndexRoute() {

    return (
        <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/carga" element={<Carga />}/>
            <Route path="/liberar-entrada" element={<LiberacaoEntrada />} />
            <Route path="/liberar-carga" element={<Liberacao />} />
            <Route path="/black-list" element={<BlacklistIndexScreen />} />
            <Route path="/black-list/add-driver-to-blacklist" element={<AddDriverToBlacklist />} />
            <Route exact path="/painel/:id" element={<PainelLiberacao />} />
            <Route exact path="/cadastros" element={<Cadastros />} />
            <Route exact path="/logs" element={<LogPage />} />
            <Route
                path="*"
                element={<Navigate to={'/'} />}
            />
        </Routes>
    );
}

export default IndexRoute;
