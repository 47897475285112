import {Api} from '../api';

export const getLogsCottonBrain = (props) => {
  const page = props.page;
  const perPage = props.perPage;
  const romaneio = props.romaneio;
  const pluma = props.pluma;
  
  if(romaneio && !pluma) {
    return Api.get(`/logs/romaneios?romaneio=${romaneio}`);
  } else if(!romaneio && pluma){
    return Api.get(`/logs/romaneios?pluma=${pluma}`);
  } else if (romaneio && pluma){
    return Api.get(`/logs/romaneios?pluma=${pluma}&romaneio=${romaneio}`);
  } else {
    return Api.get(`/logs/romaneios?page=${page}&perPage=${perPage}`);
  }
};