import React, { useState, useEffect } from "react";
import {
    Typography,
    Card,
    CardContent,
    Box,
    Grid,
    Paper,
    Chip
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faIndustry, faBox, faUserTie } from "@fortawesome/free-solid-svg-icons";

import Style from "../../../styles/Style";
import Color from "../../../styles/Color";
import { useCad } from "../../../context/cad.context";
import ModalCad from "./ModalCad";

const useStyles = Style();

function DashCad() {
    const classes = useStyles();
    const {
        cliente,
        produto,
        fornecedor,
        transportadora,
        totalLinesCliente,
        totalLinesProduto,
        totalLinesFornecedor,
        totalLinesTransportadora,
        selectDefault
    } = useCad();
    const [open, setOpen] = useState(false)
    const [data, setData] = useState({ data: [] })
    const [edit, setEdit] = useState(true);
    const [dataEdit, setDataEdit] = useState();

    const handleClose = () => {
        selectDefault(data.title, '')
        setOpen(false)
        setData({ data: [] })
        editHandleCancel();
    }

    const handleOpen = () => {
        setOpen(true);
        setDataEdit();
        setEdit(true);
    }

    const handleData = (props) => {
        setData(props);
        handleOpen();
    }

    const editHandleCancel = () => {
        setDataEdit(undefined);
        setEdit(!edit);
    }

    const editHandle = (props) => {
        setDataEdit(props);
        setEdit(false);
    }

    var getCad = [
        { title: 'Cliente', icon: faUserTie, total: totalLinesCliente, inativos: 0, data: cliente },
        { title: 'Produto', icon: faBox, total: totalLinesProduto, inativos: 0, data: produto },
        { title: 'Transportadora', icon: faTruck, total: totalLinesTransportadora, inativos: 0, data: transportadora },
        { title: 'Fornecedor', icon: faIndustry, total: totalLinesFornecedor, inativos: 0, data: fornecedor },
    ]

    useEffect(() => {
        if (data.title === "Cliente") {
            handleData(getCad[0]);
        } else if (data.title === "Produto") {
            handleData(getCad[1]);
        } else if (data.title === "Transportadora") {
            handleData(getCad[2]);
        } else if (data.title === "Fornecedor") {
            handleData(getCad[3]);
        }
        // eslint-disable-next-line
    }, [cliente, fornecedor, transportadora, produto]);

    return (
        <div>
            <Paper className={classes.rootDashHeadAlt} >
                <Grid container>
                    <Grid item xs={6} sm={6} md={4} className={classes.gridHeadLeft}>
                        <Box >
                            <Typography color="textSecondary" variant="h5" component="h2">
                                Cadastros
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={false} sm={false} md={4} />
                    <Grid item xs={6} sm={6} md={4} />
                </Grid>
            </Paper>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                {
                    getCad.map((l, i) => (
                        <Grid item xs={12} sm={12} md={6}>
                            <Card key={i} className={classes.card} onClick={() => handleData(l)} elevation={2}>
                                <CardContent className={classes.cardDashAlt}>
                                    <Box className={classes.cardBox} >
                                        <Box className={classes.cardIcon}>
                                            <FontAwesomeIcon
                                                icon={l.icon}
                                                color={Color.secundaryText}
                                                size={'3x'}
                                            />
                                        </Box>
                                        <Box className={classes.cardText}>
                                            <Typography variant="h3" component="h2" color="textSecondary">
                                                {l.total}
                                            </Typography>
                                            <Typography color="textSecondary" variant={'subtitle1'}>
                                                {l.title}
                                            </Typography>
                                            <Box style={{ display: "flex", justifyContent: "right", alignSelf: "end", marginRight: 10 }}>
                                                <Chip label={'Inativos: ' + l.inativos} color={'default'} className={classes.inputSearchIcon} size="small" />
                                            </Box>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card >
                        </Grid>
                    ))
                }
            </Grid>
            <ModalCad props={{
                open: open,
                handleClose: handleClose,
                data: data,
                edit: edit,
                dataEdit: dataEdit,
                editHandleCancel: editHandleCancel,
                editHandle: editHandle,
            }} />
        </div >

    )
}

export default DashCad;