import { makeStyles } from "@material-ui/core/styles";

import Color from "./Color";

export default function Style() {
    const drawerWidth = 300;

    return makeStyles((theme) => ({
        root: {
            flex: 1,
            display: 'flex',
        },
        rootApp: {
            height: "100%",
        },
        paper: {
            display: "flex",
            position: "relative",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "10vh",
            paddingBottom: "20vh",
            height: '92vh',
            backgroundColor: Color.secundaryText,
            borderTopRightRadius: 50,
            borderTopLeftRadius: 50,
            textAlign: "center",
        },
        avatar: {
            padding: theme.spacing(4),
            backgroundColor: Color.secundary,
        },
        avatarAlt: {
            padding: theme.spacing(10),
            backgroundColor: Color.secundary,
        },
        avatarSmall: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            backgroundColor: Color.secundary,
            "&:hover": {
                backgroundColor: Color.secundaryLight,
            }
        },
        form: {
            width: "100%",
            "& label.Mui-focused": {
                color: Color.secundary,
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: Color.secundary,
            },
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: Color.secundary,
                },
            },
        },
        textField: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            marginBottom: theme.spacing(3),
            width: '80%',
        },
        submit: {
            margin: theme.spacing(3, 1, 2),
            padding: theme.spacing(2),
            color: Color.secundaryText,
            background: Color.secundary,
            width: '80%',
            '&:hover': {
                backgroundColor: Color.secundaryLight,
                borderColor: Color.secundaryText,
            },
        },
        submitAlt: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(2),
            marginRight: theme.spacing(1),
            color: Color.secundaryText,
            background: Color.secundary,
            '&:hover': {
                backgroundColor: Color.secundaryLight,
                borderColor: Color.secundaryText,
            },
        },
        boxSwitch: {
            textAlign: 'right',
            marginRight: 10,
            fontWeight: 'bold',
        },
        Switch: {
            "& .MuiSwitch-root": {
                "& .Mui-checked": {
                    color: Color.secundary,
                },
                "& .MuiSwitch-track": {
                    backgroundColor: Color.secundaryLight,
                },
            }
        },
        rootNav: {
            flexGrow: 1,
            display: 'flex',
        },
        menuButtonNav: {
            marginRight: theme.spacing(2),
        },
        title: {
            color: Color.primaryTextLight
        },
        titleNav: {
            flexGrow: 1,
        },
        rootDash: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
                width: theme.spacing(16),
                height: theme.spacing(16),
            },
        },
        card: {
            marginTop: 8,
            marginRight: 8,
            marginLeft: 8,
            padding: 0,
            borderRadius: 3,
        },
        cardAlt: {
            border: 'none',
            color: Color.primaryTextLight
        },
        rootDashHead: {
            backgroundColor: Color.secundaryText,
            margin: 10,
            padding: 10,
            borderRadius: 3,
            textAlign: 'left',
        },
        rootDashHeadAlt: {
            backgroundColor: Color.secundaryText,
            margin: 10,
            padding: 15,
            borderRadius: 3,
            textAlign: 'left',
            marginTop: 30,
            marginBottomf: 50,
        },
        rootDashAlt: {
            backgroundColor: Color.secundaryText,
            margin: 10,
            padding: 0,
            borderRadius: 3,
            borderBottomColor: Color.light,
        },
        formControl: {
            marginTop: 15,
            margin: 10,
            backgroundColor: Color.secundaryText,
            flex: 1,
            borderRadius: 4,
            alignContent: 'right',
        },
        inputSelect: {
            width: "100%",
            padding: 0,
            margin: 0,
            "& label.Mui-focused": {
                color: Color.secundary,
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: Color.secundary,
            },
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: Color.secundary,
                    padding: 0,
                    margin: 0
                },
                padding: 0,
                margin: 0
            },
        },
        inputSelectDate: {
            width: "40%",
            "& label.Mui-focused": {
                color: Color.secundary,
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: Color.secundary,
            },
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: Color.secundary,
                },
            },
        },
        boxInputSelect: {
            flex: 1,
            marginTop: 1,
            marginBottom: 1,
            marginLeft: 10,
            marginRight: 10,
            textAlign: 'left',
        },
        appBar: {
            backgroundColor: Color.secundaryText,
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        notHide: {
            color: Color.primaryTextLight,
        },
        hide: {
            [theme.breakpoints.up('xs')]: {
                display: 'none',
            },
            [theme.breakpoints.up('sm')]: {
                display: 'inline'
            },
            display: 'none',
        },
        menuButton: {
            // marginRight: 36,
            color: Color.primaryLight,
        },
        menuButtonOpen: {
            [theme.breakpoints.up('xs')]: {
                marginLeft: "90%",
                color: Color.primaryTextLight,
            },
            [theme.breakpoints.up('sm')]: {
                marginLeft: -drawerWidth ,
                color: Color.primaryTextLight,
            },
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButtonClose: {
            color: Color.primaryTextLight,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        userIcon: {
            color: Color.primaryTextLight
        },
        menuImg: {
            position: 'absolute'
        },
        menuImgOpen: {
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuImgClose: {
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawer: {
            width: drawerWidth,
            zIndex: theme.zIndex.drawer,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            // position: "fixed",
        },
        drawerOpen: {
            [theme.breakpoints.up('xs')]: {
                width: '100%',
                zIndex: theme.zIndex.drawer - 1,
                backgroundColor: Color.secundaryText
            },
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                backgroundColor: Color.secundaryText
            },
            [theme.breakpoints.up('md')]: {
                backgroundColor: Color.secundaryText,
            },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            backgroundColor: Color.secundaryText,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(8) + 1,
            },
        },
        iconIndex: {
            zIndex: theme.zIndex.drawer + 10,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },

        content: {
            flexGrow: 1,
        },
        iconMenu: {
            marginLeft: 10,
            marginRight: 10,
            color: Color.primaryTextLight,
        },
        iconMenuSelect: {
            marginLeft: 0,
            marginRight: 10,
            paddingTop: 10,
            paddingBottom: 10,
            color: Color.secundaryText,
            backgroundColor: Color.primaryTextLight,
            paddingLeft: 10,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
        },
        textMenu: {
            color: Color.primaryTextLight,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginLeft: -10,
            padding: 1,
            marginRight: -16,
            paddingRight: 10
        },
        textMenuSelect: {
            backgroundColor: Color.primaryTextLight,
            width: '100%',
            marginLeft: -10,
            padding: 1,
            marginRight: -16,
            color: Color.secundaryText,
            display: 'flex',
            alignItems: 'center',
            paddingRight: 10
        },
        iconSubMenu: {
            padding: 10,
            color: Color.secundaryText,
        },
        iconSubMenuSelect: {
            color: Color.primaryTextLight,
            backgroundColor: Color.inputLight,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
        },
        textSubMenu: {
            color: Color.secundaryText,
            width: '100%',
            padding: 1,
        },
        textSubMenuSelect: {
            backgroundColor: Color.inputLight,
            width: '100%',
            padding: 1,
            color: Color.primaryTextLight,
        },
        subMenu: {
            backgroundColor: Color.primaryTextLight,
            marginLeft: 35,
            marginTop: -7,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            border: '1px solid ' + Color.primaryTextLight,
            '&:hover': {
                backgroundColor: Color.primaryTextLight,
                borderColor: Color.primaryTextLight,
            },
        },
        rootHeadTab: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight: {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            color: Color.secundaryText,
            backgroundColor: Color.primary,
        },
        rootTable: {
            flex: 1,
            margin: 10,
        },
        paperTable: {
            margin: 0,
            padding: 0,
            border: 'none',
            height: '100%'
        },
        table: {
            width: "100%",
            whiteSpace: "nowrap"
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        rootPagination: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
        buttonPagination: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            color: Color.secundary,
            backgroundColor: Color.secundaryText,
            "&:hover": {
                color: Color.secundaryText,
                backgroundColor: Color.secundary,
            }
        },
        buttonPaginationDisable: {
            color: Color.primaryTextLight,
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: "radial-gradient(" + Color.secundary + ", " + Color.secundaryLight + ", " + Color.primaryText + ")",
            boder: 'none'
        },
        modalAlt: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalLoad: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: Color.primary
        },
        gridHeadLeft: {
            textAlign: 'left',
            alignSelf: 'center',
            justifySelf: 'center'
        },
        gridHeadRight: {
            alignSelf: 'center',
            justifySelf: 'center',
            [theme.breakpoints.up('xs')]: {
                textAlign: 'center',
                marginTop: 10,
            },
            [theme.breakpoints.up('sm')]: {
                textAlign: 'center',
                marginTop: 10,
            },
            [theme.breakpoints.up('md')]: {
                textAlign: 'right',
                marginTop: 0
            },
        },
        gridHeadCenter: {
            textAlign: 'center',
            alignSelf: 'center',
            justifySelf: 'center'
        },
        boxBodyRight: {
            textAlign: 'right',
            alignSelf: 'center',
            justifySelf: 'center',
        },
        iconButtoBody: {
            padding: 0,
        },
        iconButtoBodyTimes: {
            backgroundColor: Color.closeColor,
            color: Color.secundaryText,
            '&:hover': {
                backgroundColor: Color.secundaryText,
                color: Color.closeColor,
            },
        },
        iconButtoBodyIdle: {
            backgroundColor: Color.atuColor,
            color: Color.secundaryText,
            '&:hover': {
                backgroundColor: Color.secundaryText,
                color: Color.atuColor,
            },
        },
        iconButtoBodyCheck: {
            backgroundColor: Color.antColor,
            color: Color.secundaryText,
            '&:hover': {
                backgroundColor: Color.secundaryText,
                color: Color.antColor,
            },
        },
        chipBox: {
            marginLeft: 10,
            backgroundColor: Color.secundary,
            color: Color.secundaryText,
            paddingLeft: 5,
            paddingRight: 5,
        },
        chipBoxAlt: {
            marginLeft: 10,
            backgroundColor: Color.primaryTextLight,
            color: Color.secundaryText,
            border: "1px solid " + Color.secundaryText,
            paddingLeft: 5,
            paddingRight: 5
        },
        colorChipSecundaryText: {
            color: Color.secundaryText
        },
        colorChipPrimaryText: {
            color: Color.secundaryText
        },
        cardBox: {
            display: "flex",
            alignItems: 'center',
        },
        cardIcon: {
            flex: 0.33,
            display: 'flex',
            background: "linear-gradient(to left top, " + Color.secundaryAlt + "," + Color.secundaryLight + ")",
            // backgroundColor: Color.secundary,
            height: 130,
            alignItems: 'center',
            justifyContent: 'center'
        },
        cardText: {
            flex: 0.66,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        modalLiberacao: {
            [theme.breakpoints.up('xs')]: {
                width: '100%',
                height: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                width: '60%',
                height: '50%',
            },
            [theme.breakpoints.up('md')]: {
                width: '25%',
                height: '45%',
            },
        },
        modalLibHead: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        closeIcon: {
            paddingLeft: 15,
            paddingRight: 15
        },
        inputSearchPaper: {
            width: "100%",
            display: 'flex',
            justifyContent: 'space-between',
        },
        inputSearch: {
            flex: 1,
            marginLeft: 15,
        },
        inputSearchIcon: {
            alignItems: 'right'
        },
        inputSelectSearch: {
            minWidth: '100%',
            "& label.Mui-focused": {
                color: Color.secundary,
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: Color.secundary,
            },
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: Color.secundary,
                },
            },
        },
        tableAction: {
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
        },
        tableHead: {
            paddingTop: 20,
            paddingBottom: 20
        },
        listColor: {
            color: Color.secundary
        },
        buttonAction: {
            display: 'flex'
        },
        alertLogin: {
            position: 'absolute',
            width: '100%',
            bottom: 5,
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 9999,
        },
        iconLogin: {
            color: Color.primaryTextLight,
        },
        alertApi: {
            position: 'absolute',
            width: '100%',
            bottom: 5,
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 9999,
        },
        submitDisabled: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(2),
            color: Color.primaryText,
            background: Color.inputLight,
            border: 'none'
        },
        modalLista: {
            [theme.breakpoints.up('xs')]: {
                height: '100%',
                width: '100%',
                borderRadius: 0
            },
            [theme.breakpoints.up('sm')]: {
                height: 500,
                width: 400
            },
        },
        inputSearchList: {
            margin: 20
        },
        fontTab: {
            fontSize: 23,
        },
        rootPainel: {
            height: 170,
        },
        containerPainel: {
            display: 'flex',
        },
        paperPainel: {
            backgroundColor: Color.light,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '80vh',
            background: Color.secundary,
            padding: 10,
        },
        mainPainel: {
            minHeight: '100vh',
            backgroundImage: "radial-gradient(circle," + Color.secundaryAlt + " 0%, " + Color.secundaryAltLight + " 100%)",
            borderRadius: 0
        },
        primaryPainel: {
            background: Color.secundary,
            padding: 10,
            margin: '5vh',
            height: '75vh',
        },
        secundaryPainel: {
            background: Color.secundary,
            padding: 10,
            margin: '2vh',
            maxHeight: '40vh',
            minHeight: '40vh',
        },
        imagePainel: {
            padding: 10,
            margin: '5vh',
        },
        clockPainel: {
            // padding: 2,
            // margin: '2vh',
            paddingTop: '5vh',
            paddingRight: '5vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        textPainel: {
            color: Color.secundaryText
        },
        dividerPainel: {
            backgroundColor: Color.secundaryText
        },
        cardPainel: {
            display: "flex",
            justifyContent: 'space-around',
            marginLeft: 20,
            marginRight: 20,
        },
        saveEdit: {
            color: Color.secundaryText,
            background: Color.secundaryLight,
            padding: 15,
            '&:hover': {
                backgroundColor: Color.secundary,
                borderColor: Color.secundaryText,
            },
        },
        selectEdit: {
            "& label.Mui-focused": {
                color: Color.secundary,
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: Color.secundary,
            },
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: Color.secundary,
                },
            },
        },
        inputEdit: {
            marginTop: 10,
            margin: 5,
        },
        inputHidden: {
            display: 'none'
        },
        liberarModal: {
            backgroundColor: Color.secundary,
            color: Color.secundaryText,
            '&:hover': {
                backgroundColor: Color.secundaryLight,
            }
        },
        rootCards: {
            height: 500
        },
        rootCardsFooters: {
            margin: 10,
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column-reverse',
            left: 0,
            bottom: 0,
        },
        rootCardsMain: {
            margin: 10,
            padding: 0,
            flex: 0.33,
            flexDirection: 'row',
        },
        backAreaMenu: {
            backgroundColor: Color.primaryTextLight,
            zIndex: 3,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5
        },
        boxData: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: "column"
        },
        searchListAlt: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            display: 'flex',
            flexDirection: 'column',
            marginRight: 5,
            marginLeft: 5
        },
        cardDash: {
            padding: 0,
            "&:last-child": {
                padding: 0
            },
        },
        cardDashAlt: {
            padding: 0,
            cursor: 'pointer',
            "&:last-child": {
                padding: 0
            },
            "&:hover": {
                border: "2px solid " + Color.secundaryAltLight,
                borderRadius: 4,
            },
        },
        buttonActionCard: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        cardLiberado: {
            backgroundColor: Color.antColorLight,
            border: 'none'
        },
        cardAguardando: {
            margin: 10,
            padding: 0,
            flex: 0.33,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: 20,
        },
        cardSpace: {
            padding: 20
        },
        buttonColorOut: {
            color: Color.closeColor
        },
        buttonColorIn: {
            color: Color.antColor
        },
        toolbarEdit: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        columnEdit: {
            border: 'none',
            margin: 40
        },
        cadDashAlt: {
            backgroundColor: Color.secundaryText,
            margin: 10,
            padding: 0,
            borderRadius: 3,
            marginTop: 100,
            minheight: "100%"
        },
        selectSearch: {
            padding: 5,
            margin: 5
        },
        MuiPickersDay: {
            day: {
                color: Color.secundary,
            },
            daySelected: {
                backgroundColor: Color.secundary,
            },
            dayDisabled: {
                color: Color.primaryTextLight,
            },
            current: {
                color: Color.primaryLight,
            },
        },
        listMenu: {
            textDecoration: "none"
        }
    }));
}