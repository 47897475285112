import React from "react";
import {
    Typography,
    Card,
    CardContent,
    Box,
    Grid,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Style from "../../../styles/Style";
import Color from "../../../styles/Color";
import { useGeral } from "../../../context/geral.context";


const useStyles = Style();

function CardsDash() {
    const classes = useStyles();
    const { resumeGeral } = useGeral();

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                {
                    resumeGeral.map((l, i) => (
                        <Grid item xs={12} sm={12} md={3}>
                            <Card key={i} className={classes.card} elevation={1}>
                                <CardContent className={classes.cardDash}>
                                    <Box
                                        className={classes.cardBox}
                                    >
                                        <Box
                                            className={classes.cardIcon}
                                        >
                                            <FontAwesomeIcon
                                                icon={l.icon}
                                                color={Color.secundaryText}
                                                size={'3x'}
                                            />
                                        </Box>
                                        <Box className={classes.cardText}>
                                            <Typography variant="h3" component="h2" color="textSecondary">
                                                {l.total}
                                            </Typography>
                                            <Typography color="textSecondary" variant={'subtitle1'}>
                                                {l.title}
                                            </Typography>
                                            <Typography color="textSecondary" variant={'caption'}>
                                                {l.subtitle}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card >
                        </Grid>
                    ))
                }
            </Grid>
        </div >

    )
}

export default CardsDash;