import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";

import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

Sentry.init({
    dsn: "https://b90e0d7a97e5467594060c46121ca016@o577993.ingest.sentry.io/6272143",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.01,
})

ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    document.getElementById('root')
);

reportWebVitals();
