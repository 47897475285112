import React from 'react'
import { Grid } from '@material-ui/core';

import SelectFazenda from "../geral/SelectFazenda";
import TabLiberacao from "./components/TabLiberacao";
import FiltersBar from "../geral/FiltersBar";
import SelectPeriodComponent from '../home/components/SelectPeriod.component';

function Liberacao() {

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                style={{padding: 15}}
            >
                <Grid item xs={12} sm={12} md={4}>
                    <SelectPeriodComponent />
                </Grid>
                <Grid item xs={false} sm={12} md={6} />
                <Grid item xs={12} sm={12} md={2}>
                    <SelectFazenda />
                </Grid>
            </Grid>
            <FiltersBar props={{title: 'Liberação Saída!'}}/>
            <TabLiberacao/>
        </div>
    )
}

export default Liberacao;
