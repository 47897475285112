import React, { useEffect, useState, useRef } from "react";
import {
    Paper,
    InputBase,
    IconButton,
    Modal,
    Box,
    Typography,
    Divider
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faTimes
} from "@fortawesome/free-solid-svg-icons";

import Style from "../../../styles/Style";
import { useCad } from "../../../context/cad.context";

const useStyles = Style();

const SearchListas = ({ props }) => {
    const timeoutRef = useRef(null);

    const classes = useStyles();
    const { 
        produto, 
        cliente, 
        transportadora, 
        fornecedor, 
        selectDefault
    } = useCad()
    const [lista, setLista] = useState([])
    const [value, setValue] = useState('');

    const id = props.id;
    const open = props.open;
    const anchorEl = props.anchorEl;
    const handleClose = props.handleClose;
    const submitSearch = props.submitSearch;
    const filterInput = props.filterInput;

    const handleCloseAlt = () => {
        handleClose();
        setLista([]);
        setValue('');
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        submitSearch(event)
        setLista([]);
        setValue('');
        handleClose();
    }


    const handleLista = () => {
        if (filterInput.name === 'Produto') {
            setLista(produto)
        }
        if (filterInput.name === 'Cliente') {
            setLista(cliente)
        }
        if (filterInput.name === 'Transportadora') {
            setLista(transportadora)
        }
        if (filterInput.name === 'Fornecedor') {
            setLista(fornecedor)
        }
    }

    const handleLoad = (event) => {
        setValue(event.target.value);
    }

    useEffect(() => {
        if (open) {
            handleLoad({ target: { value: '' } })
        }
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            selectDefault(filterInput.name, value);
        }, 1000);
        // eslint-disable-next-line
      }, [value]);

      useEffect(() => {
        handleLista()
        // eslint-disable-next-line
      }, [produto, cliente, transportadora, fornecedor])

    return (
        <Modal
            id={id}
            open={open}
            anchorEl={anchorEl}
            className={classes.modalAlt}
        >
            <Paper
                className={classes.modalLista}
                variant={'outlined'}
            >
                <Box m={1} p={1} className={classes.modalLibHead}>
                    <Box>
                        <Typography variant={'h6'} color={"textSecondary"}>
                            {'Buscar ' + filterInput.name}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleCloseAlt} className={classes.closeIcon}>
                            <FontAwesomeIcon icon={faTimes} size={'sm'} />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <form onSubmit={handleSubmit} className={classes.inputSearchList}>
                    <Paper className={classes.inputSearchPaper} variant={'outlined'}>
                        <InputBase
                            className={classes.inputSearch}
                            name={filterInput.name}
                            placeholder={"Buscar " + filterInput.name}
                            onChange={handleLoad}
                            value={value}
                        />
                        <IconButton
                            type="submit"
                            className={classes.inputSearchIcon}
                            aria-label="search"
                            disabled={!lista.some(x => x.nome === value) || value === ''}
                        >
                            <FontAwesomeIcon icon={faSearch} size={'sm'} />
                        </IconButton>
                    </Paper>
                    <Paper className={classes.searchListAlt} elevation={3}>
                        {
                            lista.map((l, i) => (
                                <div key={i}>
                                    <Box m={1} onClick={() => setValue(l.nome)}>
                                        <Typography variant={'subtitle2'} color={"textSecondary"}>
                                            {l.nome}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                </div>
                            ))
                        }
                    </Paper>
                </form>
            </Paper>
        </Modal>
    )
}

export default SearchListas;