import React from "react";
import { format } from 'date-fns'
import {
    Chip,
    Tooltip,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarCheck,
    faCalendarTimes,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import Style from "../../styles/Style";
import { tipoView } from "../../util/TabCarga";

const useStyles = Style();

const ChipList = ({ props }) => {

    const classes = useStyles();

    const data = props.data;
    const rm = props.rm;

    if (data.name === 'Placa') {
        return (
            <Tooltip title={'Remover'}>
                <Chip
                    key={data.id}
                    label={(data.value).substring(0, 3) + "-" + (data.value).substring(3, 8)}
                    icon={<FontAwesomeIcon icon={data.icon} className={classes.colorChipSecundaryText} size={'sm'} />}
                    onDelete={() => rm(data)}
                    deleteIcon={<FontAwesomeIcon icon={faTimesCircle} className={classes.colorChipSecundaryText} />}
                    size={'small'}
                    className={classes.chipBox}
                />
            </Tooltip>
        )
    } else if (data.name === 'Data') {
        if (data.value.periodo) {
            return (
                <div>
                    <Tooltip title={'Remover'} >
                        <Chip
                            key={data.id}
                            label={'Inicial: ' + format(data.value.inicial, 'dd/MM/yyyy HH:mm:ss')}
                            icon={<FontAwesomeIcon icon={faCalendarCheck} className={classes.colorChipSecundaryText} size={'sm'} />}
                            size={'small'}
                            className={classes.chipBox}
                        />
                    </Tooltip >

                    <Tooltip title={'Remover'} >
                        <Chip
                            key={data.id}
                            label={'Final: ' + format(data.value.final, 'dd/MM/yyyy HH:mm:ss')}
                            icon={<FontAwesomeIcon icon={faCalendarTimes} className={classes.colorChipSecundaryText} size={'sm'} />}
                            onDelete={() => rm(data)}
                            deleteIcon={<FontAwesomeIcon icon={faTimesCircle} className={classes.colorChipSecundaryText} />}
                            size={'small'}
                            className={classes.chipBox}
                        />
                    </Tooltip >
                </div>

            )
        } else {
            return (
                <Tooltip title={'Remover'} >
                    <Chip
                        key={data.id}
                        label={format(data.value.inicial, 'dd/MM/yyyy')}
                        icon={<FontAwesomeIcon icon={data.icon} className={classes.colorChipSecundaryText} size={'sm'} />}
                        onDelete={() => rm(data)}
                        deleteIcon={<FontAwesomeIcon icon={faTimesCircle} className={classes.colorChipSecundaryText} />}
                        size={'small'}
                        className={classes.chipBox} />
                </Tooltip >
            )
        }

    } else if (data.name === 'Tipo') {
        return (
            <Tooltip title={'Remover'}>
                <Chip
                    key={data.id}
                    label={tipoView(data.value)}
                    icon={<FontAwesomeIcon icon={data.icon} className={classes.colorChipSecundaryText} size={'sm'} />}
                    onDelete={() => rm(data)}
                    deleteIcon={<FontAwesomeIcon icon={faTimesCircle} className={classes.colorChipSecundaryText} />}
                    size={'small'}
                    className={classes.chipBox}
                />
            </Tooltip>
        )
    } else {
        return (
            <Tooltip title={'Remover'} >
                <Chip
                    key={data.id}
                    label={data.name === 'Placa' ? (data.value).substring(0, 3) + "-" + (data.value).substring(3, 8) : data.value}
                    icon={<FontAwesomeIcon icon={data.icon} className={classes.colorChipSecundaryText} size={'sm'} />}
                    onDelete={() => rm(data)}
                    deleteIcon={<FontAwesomeIcon icon={faTimesCircle} className={classes.colorChipSecundaryText} />}
                    size={'small'}
                    className={classes.chipBox}
                />
            </Tooltip >
        )
    }
}

export default ChipList;