import { Grid } from "@material-ui/core";

import SelectFazenda from "../geral/SelectFazenda";
import SelectPeriodComponent from "../home/components/SelectPeriod.component";
import TabEdit from "./components/TabEdit";
import FiltersBar from "../geral/FiltersBar";

function Carga() {

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                style={{padding: 15}}
            >
                <Grid item xs={12} sm={12} md={4}>
                    <SelectPeriodComponent />
                </Grid>
                <Grid item xs={false} sm={12} md={6} />
                <Grid item xs={12} sm={12} md={2}>
                    <SelectFazenda />
                </Grid>
            </Grid>
            <FiltersBar props={{ title: 'Edição de Cargas e Triagens!' }} />
            <TabEdit />
        </div>
    )
}

export default Carga;
