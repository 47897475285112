import { useState } from 'react';
import clsx from 'clsx';
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Grid,
    Modal,
    Paper,
    Divider,
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Tooltip
} from "@material-ui/core"

import Style from "../../../styles/Style";
import { useGeral } from "../../../context/geral.context";

const useStyles = Style();

function ModalLiberacao({ props }) {
    const [nf, setNf] = useState();
    const classes = useStyles();
    const { attEProducao, selectFazenda, fazenda, finalizarTriagem } = useGeral();

    const open = props.modal;
    const handleModal = props.handleModal;
    const value = props.value

    const submitFinalizar = (event) => {
        event.preventDefault();
        const obj = {}

        obj.notaFiscal = parseInt(nf);
        obj.idTriagem = value.idTriagem;

        finalizarTriagem(obj)
        handleModal()
    }

    const excluirNota = (event, value) => {
        event.preventDefault();
        const obj = {}
        const valid = {}

        obj.notaFiscal = 0;
        obj.liberar = false;
        obj.id = value.idTriagem;
        var fazendaInfo = fazenda.filter(x => x.id === parseInt(selectFazenda))[0]
        valid.notaFiscal = parseInt(value.notaFiscal);
        valid.empresa = fazendaInfo.empresa;
        valid.filial = fazendaInfo.filial;
        valid.val = null

        attEProducao(obj, valid)
        handleModal()
    }

    const enviarForm = event => {
        event.preventDefault();
        const obj = {}
        const valid = {}

        obj.notaFiscal = parseInt(event.target.nota.value);
        obj.liberar = true;
        obj.id = value.idTriagem;

        var fazendaInfo = fazenda.filter(x => x.id === parseInt(selectFazenda))[0]
        valid.notaFiscal = parseInt(event.target.nota.value);
        valid.empresa = fazendaInfo.empresa;
        valid.filial = fazendaInfo.filial;
        valid.val = 'S'

        attEProducao(obj, valid)
        handleModal()
    }

    const valida = (prop) => {
        var response = true
        const list = {
            0: false,
            null: false,
            undefined: false
        }
        response = list[prop] === undefined ? response : list[prop]
        return response
    }

    return (
        <Modal
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modalAlt}
        >
            <Paper
                className={classes.modalLiberacao}
                variant={'outlined'}
            >
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box m={1} p={1} className={classes.modalLibHead}>
                            <Box>
                                <Typography variant={'h5'} color="textSecondary">
                                    Liberação de carga!
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton onClick={handleModal} className={classes.closeIcon}>
                                    <FontAwesomeIcon icon={faTimes} size={'lg'} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box m={1} p={1} >
                            <Box m={1}>
                                <Typography color="textSecondary">
                                    {(value.placa).substring(0, 3) + "-" + (value.placa).substring(3, 8)}
                                </Typography>
                            </Box>
                            <Box p={1}>
                                <form onSubmit={enviarForm}>
                                    <TextField
                                        className={classes.form}
                                        required
                                        id="outlined-required"
                                        label="Nota"
                                        defaultValue={value.notaFiscal}
                                        variant="outlined"
                                        name={'nota'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={e => setNf(e.target.value)}
                                        type='number'
                                        placeholder={'Digite a Nota da Carga!'}
                                        helperText={"Insira a Nota para liberar a Carga"}
                                        disabled={valida(value.notaFiscal)}
                                    />
                                    {
                                        fazenda?.find((x) => x.id === selectFazenda)?.isBalanceAdmin && (
                                            <Box className={classes.buttonAction}>
                                                <Button
                                                    name={'Finalizar'}
                                                    disabled={valida(value.notaFiscal)}
                                                    onClick={(e) => submitFinalizar(e)}
                                                    variant="outlined"
                                                    fullWidth
                                                    className={clsx(classes.submitAlt, {
                                                        [classes.submitDisabled]: valida(value.notaFiscal)
                                                    })}
                                                >
                                                    Salvar e dar Saída
                                                </Button>
                                            </Box>
                                        )
                                    }
                                    <Box className={classes.buttonAction}>
                                        <Button
                                            disabled={valida(value.notaFiscal)}
                                            type="submit"
                                            name={'Salvar'}
                                            variant="outlined"
                                            fullWidth
                                            className={clsx(classes.submitAlt, {
                                                [classes.submitDisabled]: valida(value.notaFiscal)
                                            })}
                                        >
                                            Salvar
                                        </Button>
                                        <Tooltip title={'Excluir nota?'}>
                                            <Button
                                                disabled={!value.liberar}
                                                variant="outlined"
                                                className={clsx(classes.submitAlt, {
                                                    [classes.submitDisabled]: !value.liberar
                                                })}
                                                onClick={(e) => excluirNota(e, value)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper >
        </Modal >
    )
}

export default ModalLiberacao;