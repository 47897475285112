import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { getDetailExternal } from "../../../../services/SecService";
import { Typography, Paper } from "@material-ui/core";


const DetailEPComponet = ({ data }) => {
  const [dataDetail, setDataDetail] = useState();
  const [load, setLoad] = useState(false);

  const getDetail = async () => {
    setLoad(true)
    try {
      const result = await getDetailExternal(data.idTriagem)
      setDataDetail(result)
    } catch (e) {
      console.log('Error')
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    getDetail()
    // eslint-disable-next-line
  }, [data])

  if (load) {
    return (
      <div style={{ display: "flex", alignSelf: 'center', justifyContent: 'center', flexDirection: 'column' }} >
        <FontAwesomeIcon icon={faCircleNotch} size={'3x'} spin style={{ display: "flex", margin: 10 }} />
        <Typography variant="caption" align="center">Carregando informações Adicionais!</Typography>
      </div>
    )
  } else if (dataDetail?.hasOwnProperty('message')) {
    return (
      <>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
          <Typography variant="h6">Detalhes do Carregamento</Typography>
        </Paper>
        <Typography variant="h6" style={{ padding: 10 }}>Nada aqui!</Typography>
      </>
    )
  } else {
    return (
      <>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
          <Typography variant="h6">Detalhes do Carregamento</Typography>
        </Paper>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
          <Typography style={{ fontWeight: 'bold' }}>
            Safra:
          </Typography>
          <Typography>
            {dataDetail?.safra}
          </Typography>
        </Paper>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
          <Typography style={{ fontWeight: 'bold' }}>
            Romaneio:
          </Typography>
          <Typography>
            {dataDetail?.romaneio}
          </Typography>
        </Paper>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
          <Typography style={{ fontWeight: 'bold' }}>
            Cod. Embarque:
          </Typography>
          <Typography>
            {dataDetail?.codigo_embarque}
          </Typography>
        </Paper>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
          <Typography style={{ fontWeight: 'bold' }}>
            Cod. Local:
          </Typography>
          <Typography>
            {dataDetail?.local}
          </Typography>
        </Paper>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
          <Typography style={{ fontWeight: 'bold' }}>
            Pesagem 01:
          </Typography>
          <Typography>
            {dataDetail?.pesagem_01 + ' - ' + dataDetail?.peso_tara?.toLocaleString('pt-BR') + " Kg"}
          </Typography>
        </Paper>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
          <Typography style={{ fontWeight: 'bold' }}>
            Pesagem 02:
          </Typography>
          <Typography>
            {dataDetail?.pesagem_02 + ' - ' + dataDetail?.peso_bruto?.toLocaleString('pt-BR') + " Kg"}
          </Typography>
        </Paper>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
          <Typography style={{ fontWeight: 'bold' }}>
            Peso Liquido:
          </Typography>
          <Typography>
            {dataDetail?.peso_liquido.toLocaleString('pt-BR') + " Kg"}
          </Typography>
        </Paper>
        <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "column", margin: 5, padding: 10 }} variant="outlined">
          <Typography style={{ fontWeight: 'bold' }}>
            Notas:
          </Typography>
          {dataDetail?.notas.map((x, index) => (
            <Paper key={index} style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "column", margin: 5, padding: 10 }} variant="outlined">
              <div style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "row" }}>
                <Typography style={{ fontWeight: 'bold' }}>Código NF:</Typography>
                <Typography>{x?.codigo_nf}</Typography>
              </div>
              <div style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "row" }}>
                <Typography style={{ fontWeight: 'bold' }}>Data:</Typography>
                <Typography> {x?.data_nf}</Typography>
              </div>
              <div style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "row" }}>
                <Typography style={{ fontWeight: 'bold' }}>Peso:</Typography>
                <Typography> {x?.peso_nf?.toLocaleString('pt-BR') + " Kg"}</Typography>
              </div>
              <div style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "row" }}>
                <Typography style={{ fontWeight: 'bold' }}>Valor:</Typography>
                <Typography> {"R$ " + x?.valor_nf?.toLocaleString('pt-BR')}</Typography>
              </div >
            </Paper >
          ))}
        </Paper >
      </>
    )

  }
}

export default DetailEPComponet;