import entrada from '../../../styles/audio/entrada_liberada.mp3';
import placa from '../../../styles/audio/PLACA.mp3';
import um from '../../../styles/audio/aphanumeric/1.mp3';
import dois from '../../../styles/audio/aphanumeric/2.mp3';
import tres from '../../../styles/audio/aphanumeric/3.mp3';
import quatro from '../../../styles/audio/aphanumeric/4.mp3';
import cinco from '../../../styles/audio/aphanumeric/5.mp3';
import seis from '../../../styles/audio/aphanumeric/6.mp3';
import sete from '../../../styles/audio/aphanumeric/7.mp3';
import oito from '../../../styles/audio/aphanumeric/8.mp3';
import nove from '../../../styles/audio/aphanumeric/9.mp3';
import zero from '../../../styles/audio/aphanumeric/0.mp3';
import A from '../../../styles/audio/aphanumeric/A.mp3';
import B from '../../../styles/audio/aphanumeric/B.mp3';
import C from '../../../styles/audio/aphanumeric/C.mp3';
import D from '../../../styles/audio/aphanumeric/D.mp3';
import E from '../../../styles/audio/aphanumeric/E.mp3';
import F from '../../../styles/audio/aphanumeric/F.mp3';
import G from '../../../styles/audio/aphanumeric/G.mp3';
import H from '../../../styles/audio/aphanumeric/H.mp3';
import I from '../../../styles/audio/aphanumeric/I.mp3';
import J from '../../../styles/audio/aphanumeric/J.mp3';
import K from '../../../styles/audio/aphanumeric/K.mp3';
import L from '../../../styles/audio/aphanumeric/L.mp3';
import M from '../../../styles/audio/aphanumeric/M.mp3';
import N from '../../../styles/audio/aphanumeric/N.mp3';
import O from '../../../styles/audio/aphanumeric/O.mp3';
import P from '../../../styles/audio/aphanumeric/P.mp3';
import Q from '../../../styles/audio/aphanumeric/Q.mp3';
import R from '../../../styles/audio/aphanumeric/R.mp3';
import S from '../../../styles/audio/aphanumeric/S.mp3';
import T from '../../../styles/audio/aphanumeric/T.mp3';
import U from '../../../styles/audio/aphanumeric/U.mp3';
import V from '../../../styles/audio/aphanumeric/V.mp3';
import W from '../../../styles/audio/aphanumeric/W.mp3';
import X from '../../../styles/audio/aphanumeric/X.mp3';
import Y from '../../../styles/audio/aphanumeric/Y.mp3';
import Z from '../../../styles/audio/aphanumeric/Z.mp3';

const audioList = [
    { audio: zero, texto: '0' },
    { audio: um, texto: '1' },
    { audio: dois, texto: '2' },
    { audio: tres, texto: '3' },
    { audio: quatro, texto: '4' },
    { audio: cinco, texto: '5' },
    { audio: seis, texto: '6' },
    { audio: sete, texto: '7' },
    { audio: oito, texto: '8' },
    { audio: nove, texto: '9' },
    { audio: A, texto: 'A' },
    { audio: B, texto: 'B' },
    { audio: C, texto: 'C' },
    { audio: D, texto: 'D' },
    { audio: E, texto: 'E' },
    { audio: F, texto: 'F' },
    { audio: G, texto: 'G' },
    { audio: H, texto: 'H' },
    { audio: I, texto: 'I' },
    { audio: J, texto: 'J' },
    { audio: K, texto: 'K' },
    { audio: L, texto: 'L' },
    { audio: M, texto: 'M' },
    { audio: N, texto: 'N' },
    { audio: O, texto: 'O' },
    { audio: P, texto: 'P' },
    { audio: Q, texto: 'Q' },
    { audio: R, texto: 'R' },
    { audio: S, texto: 'S' },
    { audio: T, texto: 'T' },
    { audio: U, texto: 'U' },
    { audio: V, texto: 'V' },
    { audio: W, texto: 'W' },
    { audio: X, texto: 'X' },
    { audio: Y, texto: 'Y' },
    { audio: Z, texto: 'Z' }
];

const Player = async (props) => {
    return new Promise((resolve) => {
        let ps = new Audio(placa)
        ps.load()
        ps.autoplay = true
        ps.play()

        const letras = props.placa.split('')
        let offset = 0
        // eslint-disable-next-line
        setTimeout(() => letras.map(x => {
            setTimeout(() => {
                try {
                    let audios = audioList.find(y => y.texto === x).audio;
                    let ad = new Audio(audios);
                    ad.play()
                } catch (e) {
                    console.log(e)
                }
            }, 700 + offset);
            offset += 700;
        }), 1000)
    
        const audio = new Audio(entrada);
        audio.addEventListener("ended", resolve);
        setTimeout(() => audio.play(), 7000)
    })
}

export default Player;