import SearchPlaca from "./SearchPlaca";
import SearchListas from "./SearchListas";
import SearchData from "./SearchData";
import SearchStatus from "./SearchStatus"
import SearchTipo from "./SearchTipo";

const SearchModal = ({ props }) => {

    if (props.filterInput.name === 'Placa') {
        return (
            <SearchPlaca props={props} />
        )
    } else if (props.filterInput.name === 'Situação') {
        return (
            <SearchStatus props={props} />
        )
    } else if (props.filterInput.name === 'Data') {
        return (
            <SearchData props={props} />
        )
    } else if (props.filterInput.name === 'Tipo') {
        return (
            <SearchTipo props={props} />
        )
    } else {
        return (
            <SearchListas props={props} />
        )
    }
}

export default SearchModal;