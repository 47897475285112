import React, { useEffect, useState } from "react";

import clsx from 'clsx';
import {
    Typography,
    Card,
    CardContent,
    Grid,
    Fab,
    Tooltip
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faTimes,
    faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";

import Style from "../../../styles/Style";
import { tipoView } from "../../../util/TabCarga";
import { useGeral } from "../../../context/geral.context";
import PaginationBar from "../../geral/PaginationBar";
import { getGeralEntrada } from "../../../services/SecService";
import { attTriagemLiberacaoEntrada } from "../../../services/SecService";


const useStyles = Style();

function TabLiberacaoEntrada() {
    const classes = useStyles();
    const { period, selectFazenda } = useGeral();
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [totalLines, setTotalLines] = useState(0)
    const [actionUser, setAactionUser] = useState({ action: false, id: undefined });

    const syncGeralEntrada = async () => {
        const pagination = {
            page: page,
            perPage: rowsPerPage,
            periodo: period
        }
        try {
            const response = await getGeralEntrada(pagination);
            const dados = response.dados
            setRows(dados)
            setTotalLines(response.lines)
        } catch (e) {
            console.log(e)
        }
    }

    const rowsFilter = rows

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        syncGeralEntrada()
        // eslint-disable-next-line
    }, [period, selectFazenda, page, rowsPerPage])

    const triagemLiberada = async (props) => {

        let obj = {
            id: props.idTriagem,
            placa: props.placa,
            motorista: props.motorista,
            id_fazenda: props.idFazenda
        }

        try {
            await attTriagemLiberacaoEntrada(obj)
            syncGeralEntrada()
        } catch(e) {
            console.log(e)
        }
        setAactionUser({ action: false, id: undefined })
    }

    const handleLiberar = (props) => {
        if (actionUser.action && actionUser.id === props.idTriagem) {
            const texto = props.liberarEntrada ? 'Cancelar entrada?' : 'Confirmar entrada?';
            return (
                <div>
                    <Typography>
                        {texto}
                    </Typography>
                    <div className={classes.buttonActionCard}>
                        <Tooltip title={'Confirmar'}>
                            <Fab
                                className={classes.iconButtoBodyCheck}
                                size={"small"}
                                onClick={() => [triagemLiberada(props), syncGeralEntrada()]}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </Fab>
                        </Tooltip>
                        <Tooltip title={'Cancelar'}>

                            <Fab
                                className={classes.iconButtoBodyTimes}
                                size={"small"}
                                onClick={() => setAactionUser({ action: !actionUser.action, id: undefined })}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Fab>
                        </Tooltip>
                    </div>
                </div>
            )
        } else {
            return (
                <Tooltip title={props.liberarEntrada ? 'Clique para Cancelar ENTRADA!' : 'Clique para Confirmar ENTRADA!'}>
                    <Fab
                        className={classes.iconButtoBodyIdle}
                        size={"small"}
                        onClick={() => setAactionUser({ action: !actionUser.action, id: props.idTriagem })}
                    >
                        <FontAwesomeIcon icon={faSignInAlt} />
                    </Fab>
                </Tooltip>
            )
        }
    }

    return (
        <div flex={1} className={classes.rootCards}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
            >
                {(rowsFilter).map((row) => (
                    <Grid item xs={12} sm={12} md={4}>
                        <Card
                            elevation={2}
                            className={clsx(classes.cardAguardando, {
                                [classes.cardLiberado]: row.liberarEntrada
                            })}
                        >
                            <CardContent className={classes.cardSpace}>
                                <Typography variant="h4" component="h2">
                                    {(row.placa).substring(0, 3) + "-" + (row.placa).substring(3, 8)}
                                </Typography>
                                <Typography color="textSecondary" >
                                    Motorista: {row.motorista}
                                </Typography>
                                <Typography color="textSecondary" >
                                    Data: {row.dataTriagem}
                                </Typography>
                                <Typography color="textSecondary" >
                                    Produto: {row.nomeProduto}
                                </Typography>
                                <Typography color="textSecondary" >
                                    Tipo de Entrada: {tipoView(row.tipo)}
                                </Typography>
                            </CardContent>
                            <div >
                                {handleLiberar(row)}
                            </div>
                        </Card >
                    </Grid>
                ))
                }
            </Grid >
            <PaginationBar
                label={'Itens por Página'}
                listRows={[6, 12]}
                rowsPage={rowsPerPage}
                len={totalLines}
                changePage={handleChangePage}
                changeRowsPage={handleChangeRowsPerPage}
                page={page}
            />
        </div >
    )
}

export default TabLiberacaoEntrada;
