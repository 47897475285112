import { useState } from "react";
import {
	Typography,
	Grid,
	TextField,
	Button,
	Box,
	Input,
	InputAdornment,
	IconButton,
	FormControl,
	InputLabel,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { style } from "./Login.style";
import { useAuth } from "../../context/auth.context";
import Logo from '../../assets/Logo-Scheffer-patio.svg';
import LogoLogin from '../../assets/scheffer-black-logo.svg'

const useStyles = style();

function Login() {
	const [showPass, setShowPass] = useState(true)
	const { login } = useAuth();

	const classes = useStyles();

	function postLogin(event) {
		event.preventDefault();
		// setLoad(true);

		const user = event.target.user.value;
		const pass = event.target.pass.value;
		const origem = 'MANUAL'

		login(user, pass, origem)
	}

	return (
		<Grid container className={classes.root}>
			<Grid item xs={false} sm={false} md={7}>
				<Box className={classes.paperLeft}>
					<img className={classes.imagemLogo} src={LogoLogin} alt={'Scheffer'} />
				</Box>
			</Grid>
			<Grid item xs={12} sm={12} md={5}>
				<Box className={classes.paperRight}>
					<Box className={classes.head} >
						<Box className={classes.avatarAlt}>
							<img className={classes.imagem} src={Logo} alt={'Scheffer'} />
						</Box>
						<Typography component="h1" variant="h2" className={classes.title}>
							{process.env.REACT_APP_TITLE}
						</Typography>
					</Box>
					<form
						className={classes.form}
						onSubmit={postLogin}
					>
						<Box className={classes.inputBox}>
							<TextField
								required
								name="user"
								label="Usuário"
								className={classes.textField}
								margin="normal"
								type="text"
							/>
							<FormControl className={classes.textField}>
								<InputLabel htmlFor="standard-adornment-password">Senha *</InputLabel>
								<Input
									required
									name="pass"
									type={showPass ? "password" : "text"}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												onClick={() => setShowPass(!showPass)}
											// size={'small'}
											>
												{showPass ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</Box>
						<Box className={classes.footer}>
							<Button
								type="submit"
								variant="contained"
								name="orig"
								className={classes.submit}
							>
								Entrar
							</Button>
						</Box>
					</form>
				</Box>
			</Grid >
			<Grid item xs={false} sm={false} md={false} />
		</Grid >
	)
}

export default Login;