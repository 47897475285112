import './App.css';
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { GeralProvider } from './context/geral.context';
import { AlertLogin, AlertApi } from "./util/Alerts";
import { CadProvider } from "./context/cad.context";
import { useAuth } from './context/auth.context';
import IndexRoute from "./routes/index/routes.route";
import NavBar from "./screens/geral/NavBar";
import Login from "./screens/login/Login";
import Style from "./styles/Style";
import Loading from "./screens/geral/Loading";
import PainelLiberacao from "./screens/painel/PainelLiberacao";
import './styles/print.css'

const useStyles = Style();

function AppWrapper() {

    const classes = useStyles();
    const location = useLocation()
    const { user, loading, redirectTo, setRedirectTo } = useAuth()

    if (loading) {
        if (location.pathname !== '/' && location.pathname) {
            setRedirectTo(location.pathname)
        }
        return (
            <Routes>
                <Route path="/" element={<Loading />} />
                <Route
                    path="*"
                    element={<Navigate to="/" />}
                />
            </Routes>
        )
    }

    if (!user && !redirectTo.includes('/painel')) {
        return (
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="*"
                    element={<Navigate to="/login" />}
                />
            </Routes>
        )
    }

    if (redirectTo.includes('/painel')) {
        return (
            <div className={classes.rootApp}>
                <Routes>
                    <Route path="/painel/:id" element={<PainelLiberacao />} />
                    <Route
                        path='*'
                        element={<Navigate to={redirectTo} />}
                    />
                </Routes>
            </div>
        )
    }

    return (
        <div className={classes.rootApp}>
            <GeralProvider>
                <CadProvider>
                    <NavBar >
                        <AlertLogin />
                        <AlertApi />
                        <IndexRoute />
                    </NavBar>
                </CadProvider>
            </GeralProvider>
        </div>
    );
}

export default AppWrapper;
