import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/esm/locale/pt-BR';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import {
  Box
} from "@material-ui/core";

import Style from "../../../styles/Style";
import { useEffect, useState } from 'react';
import { useGeral } from '../../../context/geral.context';

const useStyles = Style();

const SelectPeriodComponent = () => {
  const classes = useStyles();
  const date = new Date()
  const [periodoInit, setPeriodoInit] = useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [periodoFinal, setPeriodoFinal] = useState(new Date())
  const { setPeriod } = useGeral()

  const onDateConvert = (date) => {
    let periodo = ''
    if (date === undefined) {
      const data = new Date()
      const day = (data.getDate() ).toString().padStart(2, '0')
      const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0')
      const ano = data.getFullYear()
      periodo = day + '/' + mes + '/' + ano
    } else {
      const data = new Date(date)
      const day = (data.getDate() ).toString().padStart(2, '0')
      const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0')
      const ano = data.getFullYear()
      periodo = day + '/' + mes + '/' + ano
    }

    return periodo
  }

  useEffect(() => {
    setPeriod(([onDateConvert(periodoInit), onDateConvert(periodoFinal)].toString()))
    // eslint-disable-next-line
  }, [periodoInit, periodoFinal])

  return (
    <Box className={classes.boxInputSelect}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <div
          style={{
            flex: 1,
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'space-between'
          }}
        >
          <KeyboardDatePicker
            className={classes.inputSelectDate}
            lang={'pt-br'}
            format="dd/MM/yyyy"
            label="Data inicial"
            value={periodoInit}
            onChange={date => setPeriodoInit(date)}
            inputVariant="outlined"
            name={'Período'}
            allowKeyboardControl={false}
            orientation={'landscape'}
          />
          <KeyboardDatePicker
            label={'Data Final'}
            className={classes.inputSelectDate}
            lang={'pt-br'}
            format="dd/MM/yyyy"
            value={periodoFinal}
            onChange={date => setPeriodoFinal(date)}
            inputVariant="outlined"
            name={'Período'}
            allowKeyboardControl={false}
            orientation={'landscape'}
          />
        </div>
      </MuiPickersUtilsProvider>
    </Box>
  )
}

export default SelectPeriodComponent;