import { useEffect, useState } from 'react';
import {
  Typography,
  Tooltip,
  Grid,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Button
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheck, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';


import { getLogsCottonBrain } from '../../services/logs';
import PaginationBar from '../geral/PaginationBar';
import Style from '../../styles/Style';
import Color from '../../styles/Color';
import { valueDate } from '../../util/formatDate';


const useStyle = Style();


const LogPage = () => {
  const classes = useStyle();

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [totalPage, setTotalPage] = useState(0);
  const [plumas, setPlumas] = useState();
  const [pluma, setPluma] = useState();
  const [romaneio, setRomaneio] = useState();

  const getRows = async () => {
    const pagination = {
      page: page + 1,
      perPage: perPage,
      pluma: pluma,
      romaneio: romaneio
    }

    try {
      const response = await getLogsCottonBrain(pagination);
      setRows(response.data)
      setTotalPage(response.lines)
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(event.target.value);
    setPage(0);
  };

  const handlePluma = (e) => {
    setPluma(e.target.value)
    setPlumas(undefined)
  }

  const handleRomaneio = (e) => {
    setRomaneio(e.target.value)
    setPlumas(undefined)
  }

  const PlumasComponentVerify = () => {
    if (plumas && plumas?.plumas_verificadas.length > 0) {
      return (
        <div style={{ minHeight: '50vh', maxHeight: '50vh', overflow: 'auto' }}>
          <div style={{ height: 40, display: 'flex', background: Color.inputLight, alignContent: 'center', justifyContent: 'center' }}>
            <Typography variant="h6" component="h2">Código de plumas Conferidas</Typography>
          </div>

          {
            plumas?.plumas_verificadas?.map(x => (
              <Chip
                size="small"
                label={x}
                color="primary"
                icon={<FontAwesomeIcon icon={faCheck} style={{ color: Color.secundaryText }} />}
                style={{ margin: 1, color: Color.secundaryText, background: (parseInt(x) === parseInt(pluma)? Color.atuColor : Color.antColorLight) }}
              />
            ))
          }
        </div>
      )
    } else {
      return (
        <div style={{ minHeight: '50vh', maxHeight: '50vh', overflow: 'auto' }}>
          <div style={{ height: 40, display: 'flex', background: Color.inputLight, alignContent: 'center', justifyContent: 'center' }}>
            <Typography variant="h6" component="h2">Código de plumas Conferidas</Typography>
          </div>
          <div style={{ height: '100%', display: 'flex', lignContent: 'center', justifyContent: 'center', padding: 5 }}>
            <Typography variant="overline">Selecione um romaneio para visualizar as plumas</Typography>
          </div>
        </div>
      )
    }
  }

  const PlumasComponentNotVerify = () => {
    if (plumas && plumas?.plumas_nao_verificadas.length > 0) {
      return (
        <div style={{ minHeight: '50vh', maxHeight: '50vh', overflow: 'auto' }}>
          <div style={{ height: 40, display: 'flex', background: Color.inputLight, alignContent: 'center', justifyContent: 'center' }}>
            <Typography variant="h6" component="h2">Código de plumas NÃO Conferidas</Typography>
          </div>
          {
            plumas?.plumas_nao_verificadas?.map(x => (
              <Chip
                size="small"
                label={x}
                icon={<FontAwesomeIcon icon={faTimes} style={{ color: Color.secundaryText }} />}
                style={{ margin: 1, color: Color.secundaryText, background: (parseInt(x) === parseInt(pluma)? Color.atuColor : Color.primaryTextLight) }}
              />
            ))
          }
        </div>
      )
    } else {
      return (
        <div style={{ minHeight: '50vh', maxHeight: '50vh', overflow: 'auto' }}>
          <div style={{ height: 40, display: 'flex', background: Color.inputLight, alignContent: 'center', justifyContent: 'center' }}>
            <Typography variant="h6" component="h2">Código de plumas NÃO Conferidas</Typography>
          </div>
          <div style={{ height: '100%', display: 'flex', lignContent: 'center', justifyContent: 'center', padding: 5 }}>
            <Typography variant="overline">Selecione um romaneio para visualizar as plumas</Typography>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    getRows()
    // eslint-disable-next-line
  }, [page, perPage, romaneio, pluma])

  return (
    <>
      <Grid container xs={12} >
        <Grid item xs={6} >
          <Paper style={{ margin: 10, display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
            <FormControl >
              <OutlinedInput
                placeholder='Código Romaneio'
                value={romaneio}
                onChange={(e) => handleRomaneio(e)}
                endAdornment={<FontAwesomeIcon icon={faSearch} />}
              />
              <FormHelperText id="outlined-weight-helper-text">Buscar Romaneio</FormHelperText>
            </FormControl>
            <FormControl >
              <OutlinedInput
                placeholder='Código Pluma'
                value={pluma}
                onChange={(e) => handlePluma(e)}
                endAdornment={<FontAwesomeIcon icon={faSearch} />}
              />
              <FormHelperText id="outlined-weight-helper-text">Buscar Pluma</FormHelperText>
            </FormControl>
          </Paper>
          <div>
            {romaneio}
            {pluma}
          </div>
          <Paper
            style={{
              backgroundColor: Color.secundaryText,
              margin: 10,
              padding: 0,
              borderRadius: 3,
              minheight: "100%"
            }}
          >
            <TableContainer className={classes.paperTable} >
              <Table className={classes.table} size="small">
                <TableHead >
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      <Typography color="textSecondary">
                        <b>Romaneio</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary">
                        <b>Data conferência</b>
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      <Typography color="textSecondary">
                        <b>Plumas Conferidas</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textSecondary">
                        <b>Plumas Não Conferidas</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, key) => (
                    <TableRow key={key} hover onClick={() => setPlumas(row.plumas)}>
                      <TableCell component="th" scope="row">
                        <Tooltip title={row.romaneio} placement="top-start" arrow>
                          <Typography color="textSecondary">
                            {row.romaneio}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {valueDate(row.date)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.plumas.plumas_verificadas.length}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.plumas.plumas_nao_verificadas.length}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <PaginationBar
            label={'Linhas por Página'}
            listRows={[5, 10, 15]}
            rowsPage={perPage}
            len={totalPage}
            changePage={handleChangePage}
            changeRowsPage={handleChangeRowsPerPage}
            page={page}
          />
          <Button
            style={{ marginLeft: 10 }}
            color="primary"
            startIcon={<FontAwesomeIcon icon={faArrowRight} />}
            variant="contained"
            onClick={() => getRows()}
          >
            Atulizar
          </Button>
        </Grid>
        <Grid item xs={3} >
          <Paper className={classes.cadDashAlt} >
            <PlumasComponentVerify />
          </Paper>
        </Grid>
        <Grid item xs={3} >
          <Paper className={classes.cadDashAlt} >
            <PlumasComponentNotVerify />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default LogPage;