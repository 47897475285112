import { Typography, Paper } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTruck, faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import { Timeline, TimelineItem, TimelineContent, TimelineSeparator, TimelineDot, TimelineConnector } from "@material-ui/lab";
import { tipoView } from "../../../../util/TabCarga";

const DetailDataComponent = ({data}) => {

  return (
    <>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          Fazenda:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {data?.nomeFazenda}
        </Typography>
      </Paper>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          Motorista:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {data?.motorista}
        </Typography>
      </Paper>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          Cliente:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {data?.nomeCliente}
        </Typography>
      </Paper>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          Transportadora:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {data?.nomeTransportadora}
        </Typography>
      </Paper>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          Produto:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {data?.nomeProduto}
        </Typography>
      </Paper>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          Nota Fiscal:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {(data?.notaFiscal === 0 ? 'N/A' : data?.notaFiscal) || 'N/A'}
        </Typography>
      </Paper>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          N° Instrução:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {(data?.numInstrucao === 0 ? 'N/A' : data?.numInstrucao) || 'N/A'}
        </Typography>
      </Paper>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          Estado da Triagem:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {data?.status}
        </Typography>
      </Paper>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          Ticket:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {(data?.ticket === 0 ? 'N/A' : data?.ticket) || 'N/A'}
        </Typography>
      </Paper>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          Tipo de Entrada:
        </Typography>
        <Typography style={{textAlign: "end"}}>
          {tipoView(data?.tipo)}
        </Typography>
      </Paper>
      <Timeline align="right" >
        <TimelineItem >
          <TimelineContent>
            <Paper variant="outlined" style={{ padding: 10 }}>
              <Typography variant="button" component="h1">
                Triagem
              </Typography>
              <Typography variant="caption">
                {data?.dataTriagem}
              </Typography>
            </Paper>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot color={data?.status === 'Aguardando' ? "primary" : "grey"}>
              <FontAwesomeIcon icon={faEdit} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
        </TimelineItem>
        <TimelineItem >
          <TimelineContent >
            <Paper variant="outlined" style={{ padding: 10 }}>
              <Typography variant="button" component="h1">
                Entrada
              </Typography>
              <Typography variant="caption">
                {data?.dataEntrada}
              </Typography>
            </Paper>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot color={data?.status !== 'Aguardando' && data?.status !== 'Finalizado' ? "primary" : "grey"}>
              <FontAwesomeIcon icon={faTruckLoading} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent>
            <Paper variant="outlined" style={{ padding: 10 }}>
              <Typography variant="button" component="h1">
                Saída
              </Typography>
              <Typography variant="caption">
                {data?.dataSaida}
              </Typography>
            </Paper>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot color={data?.status === 'Finalizado' ? "primary" : "grey"}>
              <FontAwesomeIcon icon={faTruck} />
            </TimelineDot>
          </TimelineSeparator>
        </TimelineItem>
      </Timeline>
    </>
  )
}

export default DetailDataComponent