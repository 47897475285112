import React from 'react'
import {
    Breadcrumbs,
    Button, Paper,
    Typography,
    TextField, Grid
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Link, useNavigate} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {addDriverToBlacklist} from "../../../services/blacklist";

const useStyles = makeStyles((theme) => ({
    componentContainer: {
        marginLeft: theme.spacing(20),
        marginRight: theme.spacing(20)
    },
    linkStyle: {
        textDecoration: "none", color: 'black'
    },
    breadcrumbs: {
        marginBottom: theme.spacing(2)
    },
    flexHeader: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerInfo: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    headerActions: {
        display: 'flex',
        alignItems: 'center',

    },
    buttonAction: {
        color: '#FEFEFE',
        background: '#81411D',
        boxShadow: "rgb(181 134 110/ 50%) 0px 8px 16px 0px"
    },
    formContainer: {
        padding: 10
    },
    textField: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    }
}))


const AddDriverToBlacklist = () => {
    const navigate = useNavigate()
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [loading, setLoading] = React.useState(false)
    const [name, setName] = React.useState('');
    const [observation, setObservation] = React.useState('');
    const [cpf, setCpf] = React.useState('');

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const formatCPF = cpf => {
        cpf = cpf.replace(/[^\d]/g, '');
        cpf = cpf.slice(0, 11)
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    const handleSaveBlockedDriver = async () => {
        try {
            setLoading(true)
            const newCpf = cpf.replace(/[^\d]/g, '').slice(0, 11)
            await addDriverToBlacklist({
                name,
                cpf:newCpf,
                observation,
                limit: selectedDate.toISOString().split('T')[0]
            })
            navigate('/black-list')
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={classes.componentContainer}>
            <div className={classes.flexHeader}>
                <div className={classes.headerInfo}>
                    <h2>Adição de Motorista a Blacklist</h2>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>

                        <Link color="inherit" to="/" className={classes.linkStyle}>
                            Home
                        </Link>

                        <Link color="inherit" to="/" className={classes.linkStyle}>
                            Blacklist
                        </Link>

                        <Typography color="textPrimary">Cadastro de Motorista</Typography>
                    </Breadcrumbs>
                </div>
                <div className={classes.headerActions}>
                    <Button variant="contained"
                            className={classes.buttonAction}
                            startIcon={<FontAwesomeIcon icon={faSave}/>}
                            onClick={handleSaveBlockedDriver}
                            disabled={loading}
                    >
                        Salvar Motorista
                    </Button>
                </div>
            </div>

            <Paper className={classes.formContainer} elevation={3}>

                <h1>Formulário de cadastro</h1>
                <form noValidate autoComplete="off">
                    <Grid container xs={12}>
                        <Grid item xs={6} className={classes.textField}>
                            <TextField id="name"
                                       label="Nome"
                                       placeholder={'Nome do motorista'}
                                       fullWidth value={name}
                                       onChange={e => setName(e.target.value)}/>
                        </Grid>
                        <Grid item xs={4} className={classes.textField}>
                            <TextField id="cpf"
                                       label="CPF"
                                       fullWidth
                                       value={formatCPF(cpf)}
                                       maxLength="14"
                                       onChange={e => setCpf(e.target.value)}/>
                        </Grid>
                        <Grid item xs={2} className={classes.textField}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="expiration-date"
                                    label="Data de Expiração"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} className={classes.textField}>
                            <TextField multiline
                                       rows={5}
                                       id="observation"
                                       label="Observação"
                                       placeholder={'Digite a observação aqui...'}
                                       fullWidth
                                       value={observation}
                                       onChange={e => setObservation(e.target.value)}/>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    )

}


export default AddDriverToBlacklist
