import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { getNotas } from "../../../../services/SecService";
import { Typography, Paper } from "@material-ui/core";


const DetailEMComponet = ({ data }) => {
  const [dataDetail, setDataDetail] = useState([]);
  const [load, setLoad] = useState(false);

  const getNota = async () => {
    setLoad(true)
    try {
      const result = await getNotas(data.idTriagem)
      setDataDetail(result)
    } catch (e) {
      console.log('Error')
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    getNota()
    // eslint-disable-next-line
  }, [data])

  if (load) {
    return (
      <div style={{ display: "flex", alignSelf: 'center', justifyContent: 'center', flexDirection: 'column' }} >
        <FontAwesomeIcon icon={faCircleNotch} size={'3x'} spin style={{ display: "flex", margin: 10 }} />
        <Typography variant="caption" align="center">Carregando informações Adicionais!</Typography>
      </div>
    )
  }
  return (
    <>
      <Paper style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "colmun", margin: 5, padding: 10 }} variant="outlined">
        <Typography variant="h6">Detalhes da Entrega</Typography>
      </Paper>
      {dataDetail.length > 0 ? (
        dataDetail.map((x, index) => (
          <Paper key={index} style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "column", margin: 5, padding: 10 }} variant="outlined">
            <div style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "row" }}>
              <Typography style={{ fontWeight: 'bold' }}>
                Código Barras:
              </Typography>
              <Typography style={{textAlign: "end"}}>
                {x?.codigo_barras}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", flexDirection: "row" }}>
              <Typography style={{ fontWeight: 'bold' }}>
                Criado em:
              </Typography>
              <Typography style={{textAlign: "end"}}>
                {x?.criado_em}
              </Typography>
            </div>
          </Paper>
        ))
      ) : (
        <Typography variant="h6" style={{ padding: 10 }}>Nada aqui!</Typography>
      )}
    </>
  )
}

export default DetailEMComponet;