import React, { forwardRef } from 'react';
import {
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Grid,

} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Style from '../../../styles/Style';
import TabCadProduto from './TabCadProduto';
import TabCadCliente from './TabCadCliente';
import TabCadTransportadora from './TabCadTransportadora';
import TabCadFornecedor from './TabCadFornecedor';


const useStyle = Style();

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCad = ({ props }) => {

    const open = props.open;
    const handleClose = props.handleClose;
    const data = props.data;

    const classes = useStyle();

    const TabAll = () => {
        if (data.title === 'Produto') {
            return (
                <TabCadProduto props={data} />
            )
        }
        if (data.title === 'Cliente') {
            return (
                <TabCadCliente props={data} />
            )
        }
        if (data.title === 'Transportadora') {
            return (
                <TabCadTransportadora props={data} />
            )
        }
        if (data.title === 'Fornecedor') {
            return (
                <TabCadFornecedor props={data} />
            )
        }
        return <div></div>
    }

    return (
        <Dialog fullScreen open={open} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} variant={'outlined'}>
                <Toolbar className={classes.toolbarEdit}>
                    <Typography variant="h6" className={classes.title} />

                    <Typography variant="h6" className={classes.title}>
                        {"Cadastro " + data.title}
                    </Typography>
                    <IconButton edge="start" aria-label="close" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Grid container className={classes.root}>
                <Grid item xs={false} sm={2} md={1} />
                <Grid item xs={12} sm={8} md={10} style={{ marginBottom: 100 }}>
                    <TabAll />
                </Grid>
                <Grid item xs={false} sm={2} md={1} />
            </Grid>
        </Dialog >
    )

}

export default ModalCad;