import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@material-ui/core/styles";

import logo from "../../assets/scheffer-black-logo.svg";


const useStyles = makeStyles((theme) => ({
    container: {
        width: '95vw',
        height: '95vh',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }
}))

const Loading = () => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.contentContainer}>
                <img width={200} height={60} src={logo} alt={'Scheffer'}/>
                <FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
                <h2>Realizando Autenticação...</h2>
            </div>
        </div>
    )
}

export default Loading;
