const Color = {
	primary: '#49a59a',
	primaryLight: 'rgba(73, 165, 154, 0.7)',
	secundary: '#81411D',
	secundaryLight: '#B75E29',
	secundaryAlt: '#5e341c',
	secundaryAltLight: '#934c24',
	primaryText: '#000000',
	primaryTextLight: '#707070',
	primaryTextLight2: '#3B3A41',
	secundaryText: '#FFFFFF',
	inputLight: '#f2f2f2',
	selection: "#FFFF00",
	atuColor: "#2196F3",
	atuColorLight: "rgba(87, 159, 227, 0.2)",
	antColor: "#28B311",
	antColorLight: "rgba(40, 179, 17, 0.2)",
	closeColor: "#FF0000",
	closeColorLight: "rgba(255, 0, 0, 0.2)",
	warningColor: "#FF8800",
	warningColorLight: "rgba(255, 136, 0, 0.7)",
	light: "rgba(0, 0, 0, 0)",
};

export default Color;