import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    Paper,
    Typography,
    Divider
} from "@material-ui/core"

import logo from '../../../styles/img/Scheffer-sub-alt.png';
import Player from "./VoicePlaca";
import Style from "../../../styles/Style";
import { useParams } from "react-router-dom";
import { getPainel, attTriagemLiberacaoEntradaAudio } from "../../../services/SecService";
import Color from "../../../styles/Color";

const useStyles = Style();

function Painel() {
    let { id } = useParams();
    const intervalRef = useRef(null);

    const [geralAlt, setGeralAlt] = useState([]);
    const [lenGeral, setLenGeral] = useState(0);
    const [fazenda, setFazenda] = useState();
    const [first, setFirst] = useState();
    const [playing, setPlaying] = useState(false);

    const classes = useStyles();

    const syncGeralAlt = async () => {
        try {
            if (id) {
                const response = await getPainel(id);
                const dados = response.dados
                setGeralAlt(dados)
                setLenGeral(dados.length)
                setFazenda(response.fazenda)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const updateAudio = async (idTriagem) => {
        try {
            if (idTriagem) {
                await attTriagemLiberacaoEntradaAudio(idTriagem)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setPlaying(false)
        }
    }

    const playAudio = async (props) => {
        setPlaying(true)

        if (lenGeral > 0 && !props?.play) {
            await Player(props).then((x) => {
                console.log(x)
            }).catch((e) => {
                console.log(e)
            }).finally(() => {
                updateAudio(props?.id)
            })
        }
    }

    const SelectPlay = () => {
        if (lenGeral > 0) {
            const result = geralAlt.find((x) => !x.play)
            setFirst(result)
            if (result && !playing) {
                playAudio(result)
            } else {
                const resultTwo = geralAlt.find((x) => x.play)
                setFirst(resultTwo)
            }
        }
    }

    useEffect(() => {
        syncGeralAlt();
        intervalRef.current = setInterval(() => {
            syncGeralAlt();
            // handleAlter();
        }, 10000); // fetch data every 60 seconds

        return () => clearInterval(intervalRef.current);
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        SelectPlay()
        // eslint-disable-next-line
    }, [geralAlt])

    const date = new Date();

    const hora = date.toLocaleTimeString().substring(0, 5);

    return (
        <Paper className={classes.mainPainel}>
            <Grid container
                direction="row"
                justifyContent="space-evenly"
                alignItems="stretch"
            >
                <Grid item xs={12} sm={10} md={10} >
                    <div className={classes.clockPainel}>
                        <Typography variant={'h2'} className={classes.textPainel}>
                            {fazenda?.nome}
                        </Typography>
                    </div>
                    <Paper elevation={2} className={classes.primaryPainel}>
                        <div className={classes.rootPainel}>
                            <div className={classes.containerPainel}>
                                <Paper elevation={0} className={classes.paperPainel}>
                                    <Typography variant={'h2'} className={classes.buttonColorIn}>
                                        {first === undefined ? '' : 'Entrada Liberada'}
                                    </Typography>
                                    <Typography variant={'h1'} className={classes.textPainel}>
                                        {first === undefined ? '' : (first?.placa).substring(0, 3) + "-" + (first?.placa).substring(3, 8)}
                                    </Typography>
                                    <Typography variant={'h2'} className={classes.textPainel}>
                                        {first === undefined ? '' : first?.motorista}
                                    </Typography>
                                </Paper>
                            </div>
                        </div>
                    </Paper>
                </Grid >
                <Grid item xs={12} sm={2} md={2}>
                    <div className={classes.clockPainel}>
                        <img width={'100%'} height={'100vh'} src={logo} alt={'Scheffer'} />
                    </div>
                    <div className={classes.clockPainel}>
                        <Typography variant={'h1'} className={classes.textPainel}>
                            {hora}
                        </Typography>
                    </div>
                    <Paper
                        elevation={2}
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            textAlign: 'center',
                            marginRight: 15,
                            backgroundColor: Color.light,
                            height: 565
                        }}
                    >
                        <Typography variant={'h5'} className={classes.buttonColorIn}>
                            {'Entrada Liberada'}
                        </Typography>
                        {
                            geralAlt.filter((x, index) => index < 4).map((l, i) => (
                                <Paper elevation={0} style={{ backgroundColor: Color.light, margin: 10 }} key={i}>
                                    <Typography variant={'h4'} style={{ margin: 0, padding: 0 }} className={[classes.textPainel]}>
                                        {(l.placa).substring(0, 3) + "-" + (l.placa).substring(3, 8)}
                                    </Typography>
                                    <br />
                                    <Typography variant={'h6'} style={{ margin: 0, padding: 0 }} className={[classes.textPainel]}>
                                        {l?.motorista}
                                    </Typography>
                                    <br />
                                    <Divider />
                                </Paper>
                            ))
                        }
                    </Paper>
                </Grid>
            </Grid >
        </Paper >
    )
}

export default Painel;
