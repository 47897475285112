import { makeStyles } from "@material-ui/core/styles";

import Color from "../../styles/Color";

export const style = () => {

	return makeStyles((theme) => ({
		root: {
			height: '100vh',
			width: '100vw',
			margin: -8,
		},
		paperLeft: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "center",
			height: '100vh',
			[theme.breakpoints.down('xs')]: {
				height: '0%',
			},
			[theme.breakpoints.down('sm')]: {
				height: '0%',
			},
		},
		paperRight: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			height: '100vh',
			backgroundColor: Color.secundary,
			paddingLeft: 40,
			paddingRight: 40,
		},
		head: {
			flex: 0.4,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		},
		avatarAlt: {
			borderColor: Color.secundaryText,
			borderWidth: 10,
			borderRadius: 300,
			height: 110,
			padding: 15,
			backgroundColor: Color.secundary,
			zIndex: 1
		},
		title: {
			color: Color.secundaryText,
			marginLeft: 20,
			fontFamily: 'Segoe UI',
			textAlign: 'center'
		},
		imagem: {
			height: 100,
			zIndex: 2,
			marginTop: 20,
			overflow: 'hidden',
		},
		imagemLogo: {
			height: '10%',
			margin: 20,
			[theme.breakpoints.down('xs')]: {
				height: '0%',
				margin: 0,
				display: 'none'
			},
			[theme.breakpoints.down('sm')]: {
				height: '0%',
				margin: 0,
				display: 'none'
			},
		},
		form: {
			flex: 0.6,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			width: '100%',
			"& label.Mui-focused": {
				color: Color.primaryText,
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: Color.primaryText,
			},
			"& .MuiOutlinedInput-root": {
				"&.Mui-focused fieldset": {
					borderColor: Color.primaryText,
				},
			},
		},
		inputBox: {
			flex: 0.3,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-between",
			width: '100%'
		},
		textField: {
			width: '80%',
			backgroundColor: Color.secundaryText,
			borderRadius: 5,
			"& input": {
				height: 40,
				borderRadius: 5,
				marginLeft: 10,
				marginRight: 10,
				fontSize: 20
			},
			[theme.breakpoints.down('xs')]: {
				width: '100%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		footer: {
			flex: 0.7,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "flex-start",
			width: '100%'
		},
		submit: {
			margin: theme.spacing(3, 1, 2),
			padding: theme.spacing(2),
			color: Color.primaryTextLight2,
			background: Color.secundaryText,
			width: '80%',
			'&:hover': {
				backgroundColor: Color.secundaryLight,
				borderColor: Color.secundaryText,
				color: Color.secundaryText,
			},
			fontWeight: 'bold',
			[theme.breakpoints.down('xs')]: {
				width: '100%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
	}));
}