import {
    Select,
    FormControl,
    MenuItem,
    FormHelperText,
    Box,
    Typography,
} from "@material-ui/core";

import Style from "../../styles/Style";
import { useGeral } from "../../context/geral.context";
import { useEffect, useState } from "react";
import { getFazenda } from "../../services/SecService";
import Logo from '../../assets/scheffer-black-logo.svg';

const useStyles = Style();

function SelectFazenda() {
    const classes = useStyles();
    const { getSelectFazenda, selectFazenda } = useGeral();

    const [fazenda, setFazenda] = useState([])

    const handleChange = (event) => {
        getSelectFazenda(event.target.value);
    };

    useEffect(() => {

        const handleGetFazenda = async () => {
            try {
                const response = await getFazenda();
                const dados = response.dados
                setFazenda(dados)
            } catch (e) {
                console.log(e)
            }
        }

        handleGetFazenda()
    }, [])


    return (

        <Box className={classes.boxInputSelect}>
            <FormControl
                className={classes.inputSelect}
                variant="outlined"
                id="nao-imprimir"
            >
                <Select
                    labelId="demo-simple-select-helper-label"
                    value={selectFazenda}
                    onChange={handleChange}
                >
                    <MenuItem value={0}>
                        <Typography variant="body2" color="textSecondary">
                            TODAS
                        </Typography>
                    </MenuItem>
                    {
                        fazenda?.map((l, i) => (
                            <MenuItem key={i} value={l.id}>
                                <Typography variant="body2" color="textSecondary">
                                    {l.nome}
                                </Typography>
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText id='nao-imprimir'>Selecione a Filial desejada</FormHelperText>
            </FormControl>
            <div id='apresentar'>
                <img src={Logo} alt="Scheffer" width={200} height={100} style={{alignItems:"flex-start", margin: 0, padding: 0}}/>
                <Typography variant="button" style={{ alignItems:"flex-start", fontWeight: "bold", margin: 0, padding: 0}} color="textSecondary">
                    {(!fazenda.find((x) => x.id === selectFazenda)?.nome ? 'TODAS AS FILIAIS' : fazenda.find((x) => x.id === selectFazenda)?.nome)}
                </Typography>
            </div>
        </Box>
    )
}

export default SelectFazenda;

