import React from "react";
import {
    Paper,
    InputBase,
    IconButton,
    Popover,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
} from "@fortawesome/free-solid-svg-icons";

import Style from "../../../styles/Style";

const useStyles = Style();

const SearchPlaca = ({ props }) => {

    const classes = useStyles();

    const id = props.id;
    const open = props.open;
    const anchorEl = props.anchorEl;
    const handleClose = props.handleClose;
    const submitSearch = props.submitSearch;
    const filterInput = props.filterInput;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <form onSubmit={submitSearch}>
                <Paper className={classes.inputSearchPaper} variant={'outlined'}>
                    <InputBase
                        className={classes.inputSearch}
                        name={filterInput.name}
                        placeholder={"Buscar " + filterInput.name}
                    />
                    <IconButton
                        type="submit"
                        className={classes.inputSearchIcon}
                        aria-label="search"
                    >
                        <FontAwesomeIcon icon={faSearch} size={'xs'} />
                    </IconButton>
                </Paper>
            </form>
        </Popover>
    )
}

export default SearchPlaca;