import React from "react";
import {
    Paper,
    Popover,
    FormControl,
    Select,
} from "@material-ui/core";

import Style from "../../../styles/Style";

const useStyles = Style();

const SearchStatus = ({ props }) => {

    const classes = useStyles();

    const id = props.id;
    const open = props.open;
    const anchorEl = props.anchorEl;
    const handleClose = props.handleClose;
    const submitSearch = props.submitSearch;
    const filterInput = props.filterInput;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Paper className={classes.inputSearchPaper} variant={'outlined'}>
                <FormControl className={classes.inputSelectSearch}>
                    <Select
                        native
                        onChange={e => [submitSearch(e), handleClose()]}
                        name={filterInput.name}
                        className={classes.selectSearch}
                    >
                        <option aria-label="None" value="" />
                        <option value={'Aguardando'}>Aguardando</option>
                        <option value={'No Pátio'}>No Pátio</option>
                        <option value={'Carregando'}>Carregando</option>
                        <option value={'Aguardando NF'}>Aguardando NF</option>
                        <option value={'Aguardando Saída'}>Aguardando Saída</option>
                        <option value={'Finalizado'}>Finalizado</option>
                    </Select>
                </FormControl>
            </Paper>
        </Popover>
    )
}

export default SearchStatus;