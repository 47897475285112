import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    TableRow,
    TableSortLabel,
    TableCell,
    TableHead,
    Toolbar,
    Typography,
    Checkbox,
    IconButton,
    Avatar
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

import Style from '../styles/Style';
import Color from '../styles/Color';

const useStyles = Style();

export const chipColor = (type) => {
    const alt = { backgroundColor: Color.inputLight, color: Color.secundaryText, marginBottom: 2, padding: 5 };
    const view = {
        "Aguardando": { backgroundColor: Color.antColor, color: Color.secundaryText, marginBottom: 2, padding: 5 },
        "Carregando": { backgroundColor: Color.warningColor, color: Color.secundaryText, marginBottom: 2, padding: 5 },
        "Aguardando NF": { backgroundColor: Color.warningColorLight, color: Color.secundaryText, marginBottom: 2, padding: 5 },
        "Aguardando Saída": { backgroundColor: Color.primary, color: Color.secundaryText, marginBottom: 2, padding: 5 },
        "No Pátio": { backgroundColor: Color.selection, color: Color.primaryTextLight, marginBottom: 2, padding: 5 },
        "Finalizado": { backgroundColor: Color.closeColor, color: Color.secundaryText, marginBottom: 2, padding: 5 },
        "Cancelado": { backgroundColor: Color.inputLight, color: Color.primaryText, marginBottom: 2, padding: 5 },
    };
    return type ? view[type] : alt;
};

export const tipoView = (type) => {
    const view = {
        EP: "Embarque de produção ",
        DP: "Desembarque de produção",
        EM: "Entrega de mercadoria",
        SE: "Serviços",
        VI: "Visitantes",
        CO: "Colaboradores",
        MO: "Moradores",
        OT: "Outros"
    };
    return type ? view[type] : null;
};


export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'placa', numeric: false, disablePadding: false, label: 'Placa' },
    { id: 'motorista', numeric: false, disablePadding: false, label: 'Motorista' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Tipo de Entrada' },
    { id: 'dataTriagem', numeric: false, disablePadding: false, label: 'Data de Triagem' },
];

export function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}

                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export const EnhancedTableToolbar = (props) => {
    const classes = useStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.rootHeadTab, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography color="inherit" variant="subtitle1" component="div">
                    {numSelected} Selecionado
                </Typography>
            ) : (
                <Typography variant="h6" id="tableTitle" component="div">
                    Cargas
                </Typography>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export function TablePaginationActions(props, disabled) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.rootPagination}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0 || disabled}
                size={'small'}
            >
                <Avatar
                    className={clsx(classes.buttonPagination, {
                        [classes.buttonPaginationDisable]: page === 0,
                    })}
                >
                    {theme.direction === 'rtl' ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : <FontAwesomeIcon icon={faAngleDoubleLeft} />}
                </Avatar>
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0 || disabled}
                size={'small'}
            >
                <Avatar
                    className={clsx(classes.buttonPagination, {
                        [classes.buttonPaginationDisable]: page === 0,
                    })}
                >
                    {theme.direction === 'rtl' ? <FontAwesomeIcon icon={faAngleRight} /> : <FontAwesomeIcon icon={faAngleLeft} />}
                </Avatar>
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={(page >= Math.ceil(count / rowsPerPage) - 1) || disabled}
                size={'small'}
            >
                <Avatar
                    className={clsx(classes.buttonPagination, {
                        [classes.buttonPaginationDisable]: page >= Math.ceil(count / rowsPerPage) - 1,
                    })}
                >
                    {theme.direction === 'rtl' ? <FontAwesomeIcon icon={faAngleLeft} /> : <FontAwesomeIcon icon={faAngleRight} />}
                </Avatar>
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={(page >= Math.ceil(count / rowsPerPage) - 1) || disabled}
                size={'small'}
            >
                <Avatar
                    className={clsx(classes.buttonPagination, {
                        [classes.buttonPaginationDisable]: page >= Math.ceil(count / rowsPerPage) - 1,
                    })}
                >
                    {theme.direction === 'rtl' ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : <FontAwesomeIcon icon={faAngleDoubleRight} />}
                </Avatar>
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};