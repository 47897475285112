import { Paper, TablePagination } from '@material-ui/core';

import Style from '../../styles/Style';
import { TablePaginationActions } from "../../util/TabCarga";

const useStyle = Style();

const PaginationBar = (props) => {

    const classes = useStyle();

    const label = props.label;
    const listRows = props.listRows;
    const rowsPage = props.rowsPage;
    const len = props.len;
    const changePage = props.changePage;
    const changeRowsPage = props.changeRowsPage;
    const page = props.page;
    const disabled = props.disabled;

    return (
        <Paper className={classes.rootCardsFooters}>
            <TablePagination
                rowsPerPageOptions={listRows}
                count={len}
                rowsPerPage={rowsPage}
                page={page}
                labelRowsPerPage={label}
                disabled={disabled}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPage}
                ActionsComponent={(e) => TablePaginationActions(e, disabled)}
                className={classes.cardAlt}
                labelDisplayedRows={({ from, to, count }) => from + ' - ' + to + ' de ' + count}
            />
        </Paper>
    )
}

export default PaginationBar;