import {env} from '../environment/env';
import {api} from './api';

const baseUrl = env.baseUrl;

export const loginServer = (user, pass) => {

    const formData = {};
    formData.username = user;
    formData.password = pass;
    formData.allowOn = 'WebSegurancaPatio'

    const url = api.post(`${baseUrl}/login`, formData).then(r => r.data);
    return url;
};

export const validServer = (token) => {
    const url = api.get(`${baseUrl}/login/validate-token`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
    return url;
};

export const refreshToken = (refreshToken) => {

    const url = api.post(`${baseUrl}/login/refresh-token`, {},{
        headers: {
            Authorization: 'Bearer ' + refreshToken
        }
    }).then(r => r.data);
    return url;
};
