import React, { useState } from "react";
import {
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TableHead,
    Tooltip,
    Chip
} from "@material-ui/core";

import Style from "../../../styles/Style";
import { useGeral } from "../../../context/geral.context";
import { tipoView, chipColor } from "../../../util/TabCarga";
import ModalEdit from './ModalEdit';
import PaginationBar from "../../geral/PaginationBar"

const useStyles = Style();

export default function TabEdit() {
    const classes = useStyles();
    const { rows, page, perPage, totalLines, setPage, setPerPage, disabled } = useGeral();
    const [open, setOpen] = useState(false)
    const [data, setData] = useState([])

    const handleClose = () => {
        setOpen(false)
        setData([])
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleData = (props) => {
        setData(props);
        handleOpen();
    }

    const rowsFilter = rows

    const emptyRows = perPage - Math.min(perPage, totalLines - page * perPage);

    const emp = Array.from(Array(emptyRows).keys());

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div>
            <Paper className={classes.rootDashAlt} elevatio={2}>
                <TableContainer className={classes.paperTable} >
                    <Table className={classes.table} aria-label="custom pagination table" size="small">
                        <TableHead >
                            <TableRow>
                                <TableCell className={classes.tableHead}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Placa</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Motorista</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Situação</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Tipo de Entrada</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Data Triagem</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Entrada em:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Saída em:</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Produto</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Cliente</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Fornecedor</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        <b>Transportadora</b>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsFilter.map((row) => (
                                <Tooltip title={'Clique para editar!'} placement="top" arrow>
                                    <TableRow hover key={row.idTriagem} onClick={() => handleData(row)}>
                                        <TableCell>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                <b>{(row.placa).substring(0, 3) + "-" + (row.placa).substring(3, 8)}</b>
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Tooltip title={row.motorista} placement="top" arrow>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {(row.motorista).length > 10 ? (row.motorista).substring(0, 10) + '...' : row.motorista}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >
                                            <Chip label={row.status} style={chipColor(row.status)} size="small" />
                                        </TableCell>
                                        <TableCell >
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {tipoView(row.tipo)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {row.dataTriagem === null ? '' : (row.dataTriagem).substring(0, 16)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {row.dataEntrada === null ? '' : (row.dataEntrada).substring(0, 16)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {row.dataSaida === null ? '' : (row.dataSaida).substring(0, 16)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Tooltip title={row.nomeProduto} placement="top" arrow>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {row.idProduto === 1 ? undefined : (row.nomeProduto).length > 10 ? (row.nomeProduto).substring(0, 10) + '...' : row.nomeProduto}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >
                                            <Tooltip title={row.nomeCliente} placement="top" arrow>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {row.idCliente === 1 ? undefined : (row.nomeCliente).length > 10 ? (row.nomeCliente).substring(0, 10) + '...' : row.nomeCliente}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >
                                            <Tooltip title={row.nomeFornecedor} placement="top" arrow>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {row.idFornecedor === 1 ? undefined : (row.nomeFornecedor).length > 10 ? (row.nomeFornecedor).substring(0, 10) + '...' : row.nomeFornecedor}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >
                                            <Tooltip title={row.nomeTransportadora} placement="top" arrow>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {row.idTransportadora === 1 ? undefined : (row.nomeTransportadora).length > 10 ? (row.nomeTransportadora).substring(0, 10) + '...' : row.nomeTransportadora}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </Tooltip>
                            ))}

                            {emptyRows > 0 && (
                                emp.map((l, i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={11}>
                                            <Typography variant={"subtitle2"} className={classes.fontTab}>
                                                &nbsp;
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <PaginationBar
                label={'Linhas por Página'}
                listRows={[5, 10]}
                rowsPage={perPage}
                len={totalLines}
                changePage={handleChangePage}
                changeRowsPage={handleChangeRowsPerPage}
                page={page}
                disabled={disabled}
            />
            <ModalEdit props={{
                open: open,
                handleClose: handleClose,
                data: data
            }} />
        </div >
    )
}
