import React, { useCallback, useEffect, useState } from "react";
import { useGeral } from "../../../context/geral.context";
import { getAguardandoNF } from "../../../services/SecService";
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TableHead,
    LinearProgress,
    IconButton,
    Chip
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faFilter, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import SearchListas from "../../geral/modal/SearchListas";

const AguardandoNfView = () => {
    const { selectFazenda } = useGeral();
    const [filaNf, setFilaNf] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const id = open ? 'simple-popover' : undefined;

    const handlerGetNF = useCallback(async () => {
        setLoading(true)
        try {
            const result = await getAguardandoNF(selectFazenda)
            setFilaNf(result.data)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [selectFazenda])

    const filterNFProduto = (filter) => {
        const search = filter.target.Produto.value
        const result = filaNf.filter((x) => x?.nomeProduto === search);
        setFilter(search)
        setFilaNf(result);
    }

    useEffect(() => {
        handlerGetNF()
    }, [handlerGetNF])

    return (
        <Paper elevation={2} style={{ paddingBottom: 20 }}>
            <div style={{ alignSelf: 'center', flex: 1, justifyContent: 'flex-end', display: 'flex', padding: 10 }}>
                <IconButton style={{ border: "gray 1px solid" }} >
                    <FontAwesomeIcon
                        icon={faRotate}
                        onClick={handlerGetNF}
                        spin={loading}
                        size="xs"
                    />
                </IconButton>
            </div>
            <TableContainer >
                <Table size={'small'}>
                    <TableHead >
                        <TableRow>
                            <TableCell >
                                Empresa
                            </TableCell>
                            <TableCell>
                                Placa
                            </TableCell>
                            <TableCell >
                                <div>
                                    <div>
                                        Produto
                                        <IconButton>
                                            <FontAwesomeIcon
                                                icon={faFilter}
                                                size="xs"
                                                onClick={() => setOpen(true)}
                                            />
                                        </IconButton>
                                    </div>
                                    {filter ? (
                                        <Chip
                                            label={filter}
                                            icon={<FontAwesomeIcon size={'sm'} />}
                                            onDelete={() => [setFilter(null), handlerGetNF()]}
                                            deleteIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                                            size={'small'}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell>
                                Romaneio
                            </TableCell>
                            <TableCell>
                                Safra
                            </TableCell>
                            <TableCell>
                                Abertura
                            </TableCell>
                            <TableCell>
                                Fechamento
                            </TableCell>
                            <TableCell>
                                Peso Tara
                            </TableCell>
                            <TableCell>
                                Peso Liquido
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableRow >
                        <TableCell colSpan={10} style={{ padding: 0, margin: 0 }}>

                            {
                                loading ? (
                                    <LinearProgress />
                                ) : (
                                    <></>
                                )
                            }
                        </TableCell>
                    </TableRow>
                    <TableBody>
                        {
                            filaNf.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {row?.nomeFazenda}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.placa}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.nomeProduto}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.ticket}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.safra}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.pesagem01}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.pesagem02}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.pesoTara}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.pesoLiquido}
                                    </TableCell>
                                    {/* <TableCell component="th" scope="row">
                                        {row?.nf ? row?.nf : "NF não foi gerada!"}
                                    </TableCell> */}
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <SearchListas props={{
                id: id,
                open: open,
                anchorEl: setAnchorEl,
                handleClose: () => setOpen(false),
                submitSearch: filterNFProduto,
                filterInput: { name: 'Produto' },
            }} />
        </Paper >
    )
}


export default AguardandoNfView;