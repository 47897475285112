import './App.css';
import Style from "./styles/Style";
import {AuthProvider} from './context/auth.context';

import AppWrapper from "./AppWrapper";

const useStyles = Style();

function App() {

    const classes = useStyles();

    return (
        <div className={classes.rootApp}>
            <AuthProvider>
                <AppWrapper/>
            </AuthProvider>
        </div>
    );
}

export default App;
