import React,{useState} from 'react';
import {
    Typography,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Tooltip,
    Grid,
    FormControl,
    Input,
    IconButton
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons';

import Style from '../../../styles/Style';
import Color from "../../../styles/Color";
import PaginationBar from "../../geral/PaginationBar";
import { useCad } from '../../../context/cad.context';
import { formatarNome } from '../../../util/FormatNome';

const useStyle = Style();

const TabCadCliente = ({ props }) => {
    const classes = useStyle();
    const [search, setSearch] = useState()

    const {
        cliente,
        selectCliente,
        setPageCliente,
        pageCliente,
        setPerPageCliente,
        perPageCliente,
        totalLinesCliente
    } = useCad()

    let rowsFilter = cliente
    let rowsLines = totalLinesCliente
    let rowsPage = pageCliente
    let rowsPerPage = perPageCliente

    let emptyRows = perPageCliente - Math.min(perPageCliente, totalLinesCliente - pageCliente * perPageCliente);

    const emp = Array.from(Array(emptyRows).keys());

    const handleChangePage = (event, newPage) => {
        setPageCliente(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPageCliente(parseInt(event.target.value, 5));
        setPageCliente(0);
    };

    const onSearchEndPoint = () => {
        selectCliente(search)
    }

    const onSearch = (event) => {
        setSearch(event.target.value);
    }

    return (
        <div>
            <Paper className={classes.cadDashAlt}>
            <Grid container xs={12} >
                    <Grid item xs={4} />
                    <Grid item xs={5} />
                    <Grid item xs={3} >
                        <FormControl style={{ borderWidth: 1, borderColor: 'black', width: '100%', flex: 1, flexDirection: 'row' }}>
                            <div style={{ width: '85%' }}>
                                <Input
                                    placeholder='Buscar'
                                    id="input-with-icon-adornment"
                                    style={{ height: 50, width: '100%' }}
                                    value={search}
                                    onChange={onSearch}
                                />
                            </div>
                            <div style={{ width: '15%' }}>
                                <IconButton onClick={onSearchEndPoint}>
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        color={Color.primaryTextLight}
                                    />
                                </IconButton>
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <TableContainer className={classes.paperTable} >
                    <Table className={classes.table} size="small">
                        <TableHead >
                            <TableRow>
                                <TableCell className={classes.tableHead}>
                                    <Typography color="textSecondary">
                                        <b>Nome</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Observação</b>
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    <Typography color="textSecondary">
                                        <b>CPF/CNPJ</b>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="textSecondary">
                                        <b>Ativo</b>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsFilter.map((row) => (
                                <Tooltip title={'Clique para editar!'} placement="top" arrow >
                                    <TableRow hover key={row.nome} >
                                        <TableCell>
                                            <Tooltip title={row.nome} placement="top-start" arrow>
                                                <Typography color="textSecondary">
                                                    {formatarNome(row.nome)}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >
                                            <Tooltip title={row?.obs} placement="top-end" arrow>
                                                <Typography color="textSecondary">
                                                    {(row?.obs).length > 20 ? (row?.obs).substring(0, 20) + '...' : row?.obs}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={row.nome} placement="top-start" arrow>
                                                <Typography color="textSecondary">
                                                    {row.cnpj_cpf}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                        <Tooltip title={row.active ? "Sim" : "Não"} placement="top" arrow>
                                            <TableCell >
                                                <FontAwesomeIcon icon={row.active ? faCheckCircle : faTimesCircle} color={row.active ? Color.antColor : Color.closeColor} />
                                            </TableCell>
                                        </Tooltip>
                                    </TableRow>
                                </Tooltip>
                            ))}

                            {emptyRows > 0 && (
                                emp.map((l, i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={11}>
                                            <Typography variant={"subtitle2"} className={classes.fontTab}>
                                                |
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <PaginationBar
                label={'Linhas por Página'}
                listRows={[5, 10, 15]}
                rowsPage={rowsPerPage}
                len={rowsLines}
                changePage={handleChangePage}
                changeRowsPage={handleChangeRowsPerPage}
                page={rowsPage}
            />
        </div>
    )
}

export default TabCadCliente;